<template>
  <nav class="navbar">
    <div class="container">
      <div class="navbar-brand">
        <router-link class="navbar-item" :to="{ name: 'dashboard' }" style="background-color:transparent !important">
          <img src="/img/logo.png" height="30">
        </router-link>
        <template v-if="show">
          <div class="navbar-burger burger" @click="toggleMenu" :class="{'is-active': $store.state.show_hamburger }" data-target="appnav">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </template>
      </div>
      <transition name="fade">
        <div id="appnav" class="navbar-menu" :class="{'is-active': $store.state.show_hamburger }">
          <template v-if="show">
            <div class="navbar-start">
              <router-link class="navbar-item" to="/inventory" >Inventory</router-link>
              <template v-if="$store.getters.is_admin">
                <router-link class="navbar-item" to="/admin" >Admin</router-link>
              </template>
            </div>
            <div class="navbar-end">
              <div class="navbar-item" v-if="$store.state.processing">
                <span class="icon"><i class="mdi mdi-autorenew spin mdi-24px"></i></span>
              </div>
              <b-dropdown class="" position="is-bottom-left">
                <a class="navbar-item" slot="trigger">
                  <span class="">Hi, {{ $store.getters.shortname }}</span>
                  <b-icon icon="menu-down"></b-icon>
                </a>
                <b-dropdown-item custom>
                  <p class="mb-2">Presentation Mode</p>
                  <div class="field">
                    <b-switch
                      v-model="presentationMode"
                      true-value="On"
                      false-value="Off">
                      {{ presentationMode }}
                    </b-switch>
                  </div>
                  <p class="text-grey">
                    Presentation mode hides sensitive data fields such as 'cost'.
                  </p>
                </b-dropdown-item>
                <hr class="dropdown-divider">
                <b-dropdown-item has-link>
                  <router-link :to="{ name: 'profile', params: {} }" exact>Profile</router-link>
                </b-dropdown-item>
                <b-dropdown-item has-link v-if='$store.getters.is_developer'>
                  <router-link :to="{ name: 'developer', params: {} }" exact>Developer</router-link>
                </b-dropdown-item>
                <hr class="dropdown-divider">
                <b-dropdown-item has-link>
                  <a href="#" @click.prevent="logout">Logout</a>
                </b-dropdown-item>
                <b-dropdown-item custom>
                  <span class="text-grey">App Version {{ $store.getters.version }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </div>
      </transition>
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    show () {
      return this.$store.getters.show_nav
    },
    presentationMode: {
      get () {
        return (this.$store.getters.presentationMode) ? 'On' : 'Off'
      },
      set (value) {
        this.$store.dispatch('present', (value === 'On'))
      }
    }
  },
  data () {
    return {
      navIsActive: false
    }
  },
  methods: {
    toggleMenu: function () {
      this.$store.dispatch('hamburger')
    },
    logout: function () {
      this.navIsActive = false
      this.$store.dispatch('logout')
    }
  },
  mounted: function () {

  }
}
</script>
