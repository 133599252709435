<template>
  <div id="app">

    <!-- Navbar -->
    <app-nav></app-nav>

    <!-- Global messaging -->
    <template v-if="$store.getters.hasGlobalMessage">
      <div class="container">
        <div class="mb-lg">
          <b-notification type="is-success" :closable="false">
            {{ $store.getters.globalMessage }}
          </b-notification>
        </div>
      </div>
    </template>

    <!-- Body -->
    <router-view v-if="ready"/>

    <!-- Initialization Status Modal -->
    <b-modal :active="!ready" :can-cancel="false">
      <div class="card">
        <div class="card-content">
          <div class="has-text-centered pa-xl">
            <p class="is-size-5 has-text-weight-bold mb-md has-text-grey" v-text="(redirecting) ? 'Redirecting...' : 'Authenticating...'"></p>
            <p class="mb-sm py-lg">
              <b-icon
                icon="loading"
                size="is-large"
                custom-class="spin has-text-grey-light">
              </b-icon>
            </p>
            <p class="mb-lg">
              <template v-if="redirecting">
                <span class="has-text-grey">You'll be redirected shortly.</span>
              </template>
              <template v-else>
                <span class="has-text-grey">Sit tight for a few seconds while we verify your access.</span>
              </template>
            </p>
            <p class="has-text-grey has-text-centered is-size-7">App Version {{ $store.getters.version }}</p>
            <p class="has-text-grey has-text-centered is-size-7"><a href="#" @click.prevent="hardReload">Reload</a></p>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- 500 Modal -->
    <b-modal :active="catastrophe" :can-cancel="false">
      <div class="card">
        <div class="card-content">
          <div class="has-text-centered py-6 px-4">
            <p class="is-size-5 has-text-weight-bold">Application error</p>
            <p class="py-lg">
              <b-icon
                icon="alert"
                size="is-large"
                custom-class="has-text-grey">
              </b-icon>
            </p>
            <div class="has-text-grey">
              <p>We've encountered an unexpected problem.</p>
              <p>Please contact your program administrator or support@revcascade.com.</p>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppNav from './AppNav'

export default {
  components: {
    'app-nav': AppNav
  },
  computed: {
    ...mapGetters([
      'authenticated',
      'initialized',
      'redirecting',
      'catastrophe'
    ]),
    ready: function () {
      return this.initialized
    }
  },
  created () {
    this.initialize()
  },
  data: function () {
    return {}
  },
  methods: {
    initialize () {
      this.$store.dispatch('initialize')
    },
    hardReload () {
      window.location.reload(true)
    }
  }
}
</script>
