<template>
  <table class="table is-hoverable is-fullwidth is-narrow is-size-7">
    <thead>
      <tr>
        <th class="table-secondary has-text-centered">Size</th>
        <th class="has-text-centered">OH</th>
        <th class="has-text-centered">OO</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in items" :key="item.id">
        <td class="table-secondary has-text-centered has-text-weight-bold">
          <span :title="'ID: ' + item.id">{{ item.size.name }}</span>
        </td>
        <!-- if inventory is present-->
        <template v-if="item.inventory !== null">
          <td class='has-text-centered'>
            <p v-text="item.inventory.on_hand"></p>
            <p class="is-size-8 has-text-grey-light" v-text="$moment(item.inventory.updated_at.date).format('MMM D')"></p>
          </td>
          <td class='has-text-centered'>
            <p>{{ item.inventory.on_order }}</p>
            <template v-if="item.inventory.available_on !== null">
              <p class="is-size-8 has-text-grey-light" v-text="$moment(item.inventory.available_on).format('MMM D')"></p>
            </template>
            <template v-else>
              <p class="is-size-8 has-text-grey-light">--</p>
            </template>
          </td>
        </template>
        <template v-else>
          <td class="has-text-centered">
            <p>0</p>
            <p class="is-size-8 has-text-grey-light">--</p>
          </td>
          <td class="has-text-centered">
            <p>0</p>
            <p class="is-size-8 has-text-grey-light">--</p>
          </td>
        </template>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ['items']
}
</script>
