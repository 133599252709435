import Dashboard from '../views/Dashboard'
import Maintenance from '../views/Maintenance'
export default [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard
  }, {
    path: '/maintenance',
    name: 'maintenance',
    component: Maintenance
  }
]
