// import vue
import Vue from 'vue'

// import supporting libs
import Buefy from 'buefy'
import InstantSearch from 'vue-instantsearch'
import VueAnalytics from 'vue-analytics'

// import supporting first party plugins
import LodashPlugin from './plugins/lodash'
import MomentPlugin from './plugins/moment'
import AxiosPlugin from './plugins/axios'
import NumbroPlugin from './plugins/numbro'

// import app
import App from './App.vue'
import router from './router'
import store from './store'

// import stylesheets
import './assets/css/vendors.scss'
import './assets/css/main.scss'

// install plugins
Vue.use(Buefy)
Vue.use(InstantSearch)
Vue.use(NumbroPlugin)
Vue.use(LodashPlugin)
Vue.use(MomentPlugin)
Vue.use(AxiosPlugin)

// configure analytics
Vue.use(VueAnalytics, {
  id: 'UA-59375821-9',
  debug: {
    sendHitTask: process.env.NODE_ENV === 'production'
  },
  router
})

// turn off console warning
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
