<template lang="html">
  <form @submit.prevent="createUser" novalidate autocomplete="off">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add User</p>
      </header>
      <section class="modal-card-body">
        <div class="mb-6">
          <div class="columns is-multiline">
            <div class="column is-full">
              <b-field
                label="Email / Username"
                :type="('email' in errors) ? 'is-danger' : ''"
                :message="('email' in errors) ? errors['email'][0] : ''"
                expanded>
                <b-input
                  autocomplete="off"
                  type="text"
                  name="email"
                  v-model="email"
                ></b-input>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field
                label="First Name"
                :type="('first_name' in errors) ? 'is-danger' : ''"
                :message="('first_name' in errors) ? errors['first_name'][0] : ''"
                expanded>
                <b-input
                  autocomplete="off"
                  type="text"
                  name="first_name"
                  v-model="first_name"
                ></b-input>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field
                label="Last Name"
                :type="('last_name' in errors) ? 'is-danger' : ''"
                :message="('last_name' in errors) ? errors['last_name'][0] : ''"
                expanded>
                <b-input
                  autocomplete="off"
                  type="text"
                  name="last_name"
                  v-model="last_name"
                ></b-input>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field
                label="Password"
                :type="('password' in errors) ? 'is-danger' : ''"
                :message="('password' in errors) ? errors['password'][0] : ''"
                expanded>
                <b-input
                  autocomplete="off"
                  type="password"
                  name="password"
                  v-model="password"
                ></b-input>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field
                label="Confirm Password"
                :type="('password_confirmation' in errors) ? 'is-danger' : ''"
                :message="('password_confirmation' in errors) ? errors['password_confirmation'][0] : ''"
                expanded>
                <b-input
                  autocomplete="off"
                  type="password"
                  name="password_confirmation"
                  v-model="password_confirmation"
                ></b-input>
              </b-field>
            </div>
            <div class="column is-half">
              <label class="label mb-6">Roles</label>
              <div class="field">
                <b-checkbox name='is_admin' v-model="is_admin">Make user an Administrator</b-checkbox>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot justify-end">
        <button type="button" class="button rounded" @click="$parent.close()">Close</button>
        <button type="submit" class="button is-primary rounded" :disabled="$store.getters.busy">Add User</button>
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  computed: {

  },
  data: function () {
    return {
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      password_confirmation: '',
      is_admin: false,
      errors: []
    }
  },
  methods: {
    formData: function () {
      return {
        email: this.email,
        first_name: this.first_name,
        last_name: this.last_name,
        password: this.password,
        password_confirmation: this.password_confirmation,
        is_admin: this.is_admin
      }
    },
    resetForm: function () {
      this.errors = {}
      this.email = null
      this.first_name = null
      this.last_name = null
      this.password = null
      this.password_confirmation = null
    },
    createUser: function () {
      this.$store.dispatch('process')
      this.$axios.post('users', this.formData(), {
        headers: { 'Authorization': this.$store.getters.token }
      }).then(response => {
        this.$emit('processed')
        this.resetForm()
        this.$buefy.toast.open({
          type: 'is-success', message: 'User added.'
        })
        this.$store.dispatch('rest')
        this.$parent.close()
      }).catch(error => {
        this.$store.dispatch('rest')
        if (error.response && error.response.status === 422) {
          this.errors = error.response.data.errors
          this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
        }
      })
    }
  }
}
</script>
