import Profile from '../views/Profile'
import Password from '../views/Password'
export default [
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  }, {
    path: '/profile/password',
    name: 'password',
    component: Password
  }
]
