<template>
  <div class="section">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-two-thirds">
          <div class="has-background-white rounded-lg px-xxl py-xxl">
            <transition name="fade" mode="out-in">
              <router-view></router-view>
            </transition>
          </div>
          <div class="py-lg is-size-8">
            <p class="has-text-grey has-text-centered mb-sm">App Version {{ $store.getters.version }}</p>
            <p class="has-text-centered"><a class="has-text-grey" href="#" @click.prevent="hardReload">Reload</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {}
  },
  methods: {
    hardReload () {
      window.location.reload(true)
    }
  }
}
</script>
