import Admin from '../views/Admin'
export default [
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    meta: {
      title: 'Admin'
    }
  }
]
