import Auth from '../views/Auth'
import Login from '../views/Login.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
export default [
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
    children: [{
      path: 'login',
      component: Login
    }, {
      path: 'forgot',
      component: ForgotPassword
    }]
  }
]
