<template lang="html">
  <div class="section">
    <div class="container">

      <!-- Breadcrumbs -->
      <breadcrumbs>
        <li><router-link :to="{ name: 'admin', params: {} }" exact>Admin</router-link></li>
        <li class="is-active"><a href="#">Statistics</a></li>
      </breadcrumbs>

      <!-- Content -->
      <div class="has-background-white rounded-lg p-6">
        <h2 class="text-3xl mb-4">Platform Statistics</h2>

        <!-- Toolbar -->
        <div class="columns">
          <div class="column is-one-third">
            <div class="mb-4">
              <brands></brands>
            </div>
          </div>
          <div class="column is-one-third">
            <div class="mb-4">
              <styles></styles>
            </div>
          </div>
          <div class="column is-one-third">
            <div class="mb-4">
              <vendors></vendors>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
import Brands from './Brands'
import Vendors from './Vendors'
import Styles from './Styles'
export default {
  components: {
    breadcrumbs: Breadcrumbs,
    brands: Brands,
    styles: Styles,
    vendors: Vendors
  },
  computed: {

  },
  data: function () {
    return {
      loading: false
    }
  },
  filters: {

  },
  methods: {

  },
  mounted: function () {

  },
  props: [],
  watch: {}
}
</script>
