<template>
  <div class="section">
    <div class="container">
      <!-- Breadcrumbs -->
      <breadcrumbs>
        <li class="is-active"><a href="#">Search</a></li>
      </breadcrumbs>
      <!-- Content -->
      <div class="has-background-white rounded-lg px-xl pa-lg">
        <!-- Page Title -->
        <div class="">
          <div class="pt-sm pb-md">
            <h2 class="is-size-3">Style Search</h2>
          </div>
        </div>
        <!-- Algolia Search -->
        <ais-index app-id="D0V47K9YQT" api-key="69638e9e4bae2d6d97d2d5e1c024cc78" :index-name="index" :auto-search="false">
          <!-- Search Box -->
          <div class="search-box py-lg mb-lg has-border-top has-border-bottom">
            <div class="columns">
              <div class="column">
                <ais-input
                  placeholder="Search for styles..."
                  :class-names="{
                    'ais-input': 'input is-large'
                  }"
                ></ais-input>
              </div>
              <div class="column is-narrow">
                <ais-clear :class-names="{
                  'ais-clear': 'button is-primary is-fullwidth is-large'
                }"></ais-clear>
              </div>
            </div>
          </div>
          <!-- Results -->
          <div class="mb-3">
            <ais-results>
              <template slot-scope="{ result }">
                <div class="has-border-bottom pb-lg mb-lg">
                  <div class="columns">
                    <div class="column is-one-fifth">
                      <p class="has-text-centered">
                        <router-link :to="{ name: 'search.detail', params: { id: result.objectID } }">
                          <img v-if="result.cdn_image_url !== null" class="py-md" style="max-height:150px;" :src="result.cdn_image_url|cloudinary" @error="fallback" >
                          <img v-else-if="result.vendor === 'Eastbay'" class="py-md" style="max-height:150px;" :src="result.source_image_url|resizer" @error="fallback">
                          <img v-else-if="result.source_image_url !== null" class="py-md" style="max-height:150px;" :src="result.source_image_url" @error="fallback">
                          <img v-else class="py-md" src="/img/unavailable-lg.png" >
                        </router-link>
                      </p>
                    </div>
                    <div class="column">
                      <p class="mb-sm">
                        <router-link class="has-text-weight-bold" :to="{ name: 'search.detail', params: { id: result.objectID } }">{{ result.objectName }}</router-link>
                      </p>
                      <div class="flex">
                        <div class="col-lg-12 is-size-7">
                          <p class="mb-xs"><span class="has-text-weight-bold">Vendor</span> {{ result.vendor }}</p>
                          <template v-if="result.vendor === 'Eastbay'">
                            <p class="mb-xs"><span class="has-text-weight-bold">Model</span> {{ result.brand }} {{ result.model_name }}</p>
                            <p class="mb-xs"><span class="has-text-weight-bold">Color</span> {{ result.color }}</p>
                            <p class="mb-xs"><span class="has-text-weight-bold">{{ result.brand }} SKU</span> {{ result.style_number }}</p>
                            <p class="mb-xs"><span class="has-text-weight-bold">Eastbay SKU</span> {{ result.eastbay_style_number }}</p>
                          </template>
                          <template v-else>
                            <p class="mb-xs"><span class="has-text-weight-bold">Model</span> {{ result.brand }} {{ result.model_name }}</p>
                            <p class="mb-xs"><span class="has-text-weight-bold">Color</span> {{ result.color }}</p>
                            <p class="mb-xs"><span class="has-text-weight-bold">{{ result.vendor }} SKU</span> {{ result.style_number }}</p>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </ais-results>
          </div>
        </ais-index>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
export default {
  computed: {},
  components: {
    'breadcrumbs': Breadcrumbs
  },
  data: function () {
    return {
      index: process.env.VUE_APP_SEARCH_INDEX,
      behavior: 'all',
      excludeExternal: true
    }
  },
  filters: {
    cloudinary: function (cdnId) {
      return 'https://res.cloudinary.com/revcascade/image/upload/' + cdnId + '.jpg'
    },
    resizer: function (url) {
      return (url !== null) ? url.replace(/^http:\/\//i, 'https://').replace('eastbay', 'footlocker') + '?wid=400&hei=400' : null
    }
  },
  methods: {
    fallback: function (event) {
      event.target.src = '/img/unavailable-lg.png'
    }
  }
}
</script>
