<template>
  <div class="section">
    <template v-if="loading">
      <p class="has-text-centered has-text-grey-light py-xxl">Loading Related Styles...</p>
    </template>
    <template v-else-if="styles.length == 0">
      <p class="has-text-centered has-text-grey-light py-lg">No related styles found.</p>
    </template>
    <template v-else>
      <div class="columns is-multiline is-mobile">
        <div class="column is-one-fifth-desktop is-one-quarter-tablet is-half-mobile" v-for="style in styles" :key="style.id">
          <p class="has-text-centered">
            <router-link :to="{ name: 'search.detail', params: { id: style.id } }" exact>
              <img v-if="style.cdn_image_url" style="max-height:200px" :src="style.cdn_image_url|cloudinary" @error="fallback" >
              <img v-else-if="style.source_image_url !== null" style="max-height:200px" :src="style.source_image_url|https" @error="fallback">
              <img v-else class="py-sm" style="max-height:200px" src="/img/unavailable-lg.png" >
            </router-link>
          </p>
          <p class="is-size-8 has-text-centered has-text-grey-light">{{ (style.eastbay_style_number != "N/A") ? style.eastbay_style_number : style.style_number }}</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {

  components: {

  },
  computed: {

  },
  data: function () {
    return {
      loading: false,
      styles: []
    }
  },
  filters: {
    https: function (url) {
      return (url !== null) ? url.replace(/^http:\/\//i, 'https://') : null
    },
    cloudinary: function (cdnId) {
      return 'https://res.cloudinary.com/revcascade/image/upload/' + cdnId + '.jpg'
    }
  },
  methods: {
    fallback: function (event) {
      event.target.src = '/img/unavailable-sm.png'
    },
    loadRelated: function () {
      this.loading = true
      this.$axios.get('styles/' + this.style_id + '/related').then(response => {
        this.styles = response.data.data
        this.loading = false
      })
    }
  },
  mounted: function () {
    if (this.style_id !== null) this.loadRelated()
  },
  props: ['style_id'],
  watch: {
    style_id: function (value) {
      this.loadRelated()
    }
  }
}
</script>
