<template>
  <div class="login">
    <form @submit.prevent="attempt" novalidate>
      <p class="has-text-centered mb-lg">{{ message }}</p>
      <div class="mb-xxl">
        <b-field label="Email">
          <b-input
            type="email"
            placeholder="Email Address"
            size="is-large"
            v-model="email"
          ></b-input>
        </b-field>
        <b-field label="Password">
          <b-input
            password-reveal
            placeholder="Password"
            size="is-large"
            type="password"
            v-model="password"
          ></b-input>
        </b-field>
      </div>
      <b-button
        native-type="submit"
        type="is-primary"
        size="is-large"
        :disabled="$store.state.processing"
        :loading="$store.state.processing"
        expanded>Login
      </b-button>
    </form>
      <!-- <router-link to="/auth/forgot">Forgot password?</router-link> -->
  </div>
</template>

<script>

export default {
  created: function () {

  },
  computed: {

  },
  data: function () {
    return {
      email: null,
      password: null,
      message: 'Please sign in.'
    }
  },
  methods: {
    /**
     * Attempt to obtain an access token from the API. On success, store
     * the token in local storage.
     */
    attempt () {
      this.$store.dispatch('process')
      const data = {
        email: this.email,
        password: this.password
      }
      this.$axios.post('/auth/token', data).then(response => {
        this.$store.dispatch('authenticate', response.data)
        this.$store.dispatch('rest')
      }).catch(() => {
        this.$store.dispatch('rest')
        this.$buefy.toast.open({
          duration: 3000,
          message: `Invalid username and password.`,
          type: 'is-danger'
        })
      })
    }
  }
}
</script>
