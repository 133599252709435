<template>
  <div class="section">
    <div class="container">
      <!-- Breadcrumbs -->
      <breadcrumbs>
        <li><router-link :to="{ name: 'search', params: {} }" exact>Search</router-link></li>
        <li class="is-active"><a href="#">Style Detail</a></li>
      </breadcrumbs>
      <!-- Content -->
      <div class="has-background-white rounded-lg pa-lg">
        <template v-if="loading || style === null">
          <p class="has-text-centered has-text-grey-light py-xxl">Loading Style...</p>
        </template>
        <template v-else>
          <!-- Style Header -->
          <div>
            <div class="has-border-bottom pb-lg mb-lg">
              <h2 class="is-size-3 mb-none">{{ (style.eastbay_style_name) ? style.eastbay_style_name : style.style_name }}</h2>
              <p class="has-text-grey">from {{ style.brand.name }}</p>
            </div>
          </div>
          <!-- Style Details -->
          <div class="mb-lg">
            <template v-if="style.vendor.id != 1000">
              <div class="pa-md rounded-lg mb-lg is-size-7" style="background:#EEF1F9">
                <span class="has-text-danger has-text-weight-bold">Attention!</span>
                This style was sourced from {{ style.vendor.name }} and may not be carried by Eastbay. Please check inventory details carefully.
              </div>
            </template>
            <div class="columns">
              <div class="column is-half">
                <p class="has-text-centered">
                  <img v-if="style.cdn_image_url" style="max-height:400px !important" :src="style.cdn_image_url|cloudinary" @error="fallback" >
                  <img v-else-if="style.vendor.id === 1000" class="rounded" style="max-height:400px !important" :src="style.source_image_url|resizer" @error="fallback">
                  <img v-else-if="style.source_image_url !== null" class="rounded" style="max-height:400px !important" :src="style.source_image_url|https" @error="fallback">
                  <img v-else class="py-md" style="max-height:400px !important" src="/img/unavailable-lg.png" >
                </p>
              </div>
              <div class="column is-half">
                <h6 class="mb-md pb-sm has-border-bottom has-border-secondary has-border-lg has-text-weight-bold is-size-6">Style Details</h6>
                <template v-if="!$store.getters.presentationMode">
                  <p class="mb-lg is-size-7">
                    <span class='has-text-danger has-text-weight-bold'>Note: </span>
                    All fields are currently visible. Activate presentation
                    mode in the user menu (upper right corner) to hide
                    sensitive data.
                  </p>
                </template>
                <div class="is-size-7">
                  <p class="mb-xs"><span class="has-text-weight-bold">Vendor</span> {{ style.vendor.name }} </p>
                  <p class="mb-xs"><span class="has-text-weight-bold">Brand</span> {{ style.brand.name }} </p>
                  <p class="mb-xs"><span class="has-text-weight-bold">Gender</span> {{ (style.gender) ? style.gender : '--' }}</p>
                  <p class="mb-xs "><span class="has-text-weight-bold">Model</span> {{ style.model_name }}</p>
                  <p class="mb-xs"><span class="has-text-weight-bold">Model #</span> {{ style.model_number }}</p>
                  <p class="mb-xs"><span class="has-text-weight-bold">{{ style.brand.name }} SKU</span> {{ style.style_number }}</p>
                  <p class="mb-xs"><span class="has-text-weight-bold">MSRP</span> {{ (style.msrp) ? '$' + style.msrp : '--' }}</p>
                  <template v-if="style.vendor.id == 1000">
                    <p class="mb-xs"><span class="has-text-weight-bold">Eastbay SKU</span> {{ style.eastbay_style_number }}</p>
                    <p class="mb-xs"><span class="has-text-weight-bold">Eastbay Retail Price</span> ${{ (style.eastbay_retail_price) ? style.eastbay_retail_price.toFixed(2) : '--' }}</p>
                    <p class="mb-xs"><span class="has-text-weight-bold">Eastbay Online Price</span> ${{ (style.eastbay_online_price) ? style.eastbay_online_price.toFixed(2) : '--' }}</p>
                  </template>
                  <template v-if="!$store.getters.presentationMode">
                    <template v-if="style.vendor.id == 1000">
                      <p class="mb-xs">
                        <span class="has-text-weight-bold">Eastbay Avg Cost</span> ${{ (style.eastbay_avg_cost_price) ? style.eastbay_avg_cost_price.toFixed(2) : '--' }}
                      </p>
                    </template>
                    <template v-else>
                      <p class="mb-xs">
                        <span class="has-text-weight-bold">Vendor Cost</span> ${{ (style.cost) ? style.cost.toFixed(2) : '--' }}
                      </p>
                    </template>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <!-- Inventory Details -->
          <div class="columns">
            <!-- Eastbay Inventory -->
            <div class="column is-half">
              <template v-if="style.is_eastbay_style">
                <inventory :reference="style"></inventory>
              </template>
              <template v-else>
                <search-eastbay :reference="style"></search-eastbay>
              </template>
            </div>
            <!-- Vendor Inventory -->
            <div class="column is-half">
              <!-- Nike Live Inventory -->
              <template v-if="style.brand.code === 'nike' || style.brand.code === 'jordan'">
                <SearchNike :reference="style"></SearchNike>
              </template>
              <!-- Vendor Inventory -->
              <template v-else-if="style.is_eastbay_style">
                <search-vendors :reference="style"></search-vendors>
              </template>
              <template v-else>
                <inventory :reference="style"></inventory>
              </template>
            </div>
          </div>
          <!-- Additional Colors -->
          <div class="">
            <h6 class="mb-sm pb-sm has-border-bottom has-border-secondary has-border-lg has-text-weight-bold">Related Styles</h6>
            <div class="mb-lg">
              <p class='has-text-grey'><span class="has-text-weight-bold">Model</span> {{ style.brand.name }} {{ style.model_name }}</p>
              <p class='has-text-grey'><span class="has-text-weight-bold">Model #</span> {{ style.model_number }}</p>
            </div>
            <search-related :style_id="style.id"></search-related>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
import SearchEastbay from './SearchEastbay'
import SearchNike from './SearchNike'
import SearchVendors from './SearchVendors'
import StyleInventory from './StyleInventory'
import SearchRelated from './SearchRelated'
export default {

  components: {
    'breadcrumbs': Breadcrumbs,
    'search-related': SearchRelated,
    'search-eastbay': SearchEastbay,
    'search-vendors': SearchVendors,
    'inventory': StyleInventory,
    SearchNike
  },
  computed: {
    carried_by_eastbay: function () {
      if (this.style !== null && this.style.vendors.length > 0) {
        return false
      }
      return true
    }
  },
  data: function () {
    return {
      loading: true,
      style: null
    }
  },
  filters: {
    https: function (url) {
      return (url !== null) ? url.replace(/^http:\/\//i, 'https://') : null
    },
    cloudinary: function (cdnId) {
      return 'https://res.cloudinary.com/revcascade/image/upload/' + cdnId + '.jpg'
    },
    resizer: function (url) {
      return (url !== null) ? url.replace(/^http:\/\//i, 'https://') + '?wid=400&hei=400' : null
    }
  },
  methods: {
    loadStyle: function () {
      this.loading = true
      this.$axios.get('styles/' + this.$route.params.id + '?mode=latest').then(response => {
        this.style = response.data.data
        this.loading = false
      })
    },
    simpleDate: function (value) {
      if (value !== null) {
        return this.$moment(value).format('MMM D')
      }
      return ''
    },
    fallback: function (event) {
      event.target.src = '/img/unavailable-lg.png'
    }
  },
  mounted: function () {
    this.loadStyle()
  },
  watch: {
    $route: function (to, from) {
      this.loadStyle()
    }
  }
}
</script>
