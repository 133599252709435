<template lang="html">
  <form @submit.prevent="editPassword" novalidate autocomplete="false" v-if="user !== null">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Reset Password</p>
      </header>
      <section class="modal-card-body">
        <div class="pt-4 pb-6">
          <b-field label="New Password"
            :type="('password' in errors) ? 'is-danger' : ''"
            :message="('password' in errors) ? errors['password'][0] : ''"
            >
            <b-input
              password-reveal
              type="password"
              v-model="password"
            ></b-input>
          </b-field>
          <b-field label="Confirm New Password">
            <b-input
              password-reveal
              type="password"
              v-model="password_confirmation"
            ></b-input>
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot justify-end">
        <button type="button" class="button" @click="close()">Close</button>
        <button type="submit" class="button is-primary" :disabled="saving">Update</button>
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  computed: {

  },
  data: function () {
    return {
      saving: false,
      errors: [],
      password: null,
      password_confirmation: null
    }
  },
  methods: {
    formData: function () {
      return {
        password: this.password,
        password_confirmation: this.password_confirmation
      }
    },
    resetForm: function () {
      this.errors = []
      this.password = null
      this.password_confirmation = null
    },
    close: function () {
      this.resetForm()
      this.$parent.close()
    },
    editPassword: function () {
      this.saving = true
      this.$axios.patch('users/' + this.user.id + '/reset', this.formData()).then(response => {
        this.saving = false
        this.$emit('updated')
        this.$parent.close()
      }).catch(error => {
        this.saving = false
        if (error.response && error.response.status === 422) {
          this.errors = error.response.data.errors
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      })
    }
  },
  mixins: [],
  props: ['user']
}
</script>
