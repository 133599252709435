<template>
  <div class="section">
    <div class="container">
      <!-- Breadcrumbs -->
      <breadcrumbs>
        <li><router-link :to="{ name: 'admin', params: {} }" exact>Admin</router-link></li>
        <li class="is-active"><a href="#">Users</a></li>
      </breadcrumbs>
      <div class="has-background-white rounded-lg pa-xl">
        <!-- Toolbar -->
        <div class="columns is-mobile">
          <div class="column">
            <h2 class="is-size-3">Users</h2>
          </div>
          <div class="column">
            <p class="has-text-right">
              <button class="button is-primary is-medium rounded" @click="showCreateModal = true">
                Add User
              </button>
            </p>
          </div>
        </div>
        <!-- Search -->
        <div class="columns">
          <div class="column">
            <b-input v-model="searchQuery" size="is-medium" placeholder="Search for users..." @input="search"></b-input>
          </div>
          <div class="column is-narrow">
            <p class="has-text-right">
              <button class="button is-text is-medium" @click="searchQuery = null" :disabled="name === null" style="text-decoration:none">
                Clear
              </button>
            </p>
          </div>
        </div>
        <!-- Table Content -->
        <table class="table is-fullwidth is-size-7">
          <thead>
            <tr>
              <th>Name</th>
              <th class="has-text-right">Status</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="loading">
              <tr v-for="index in 15" :key="index">
                <td colspan="2">
                  <div class="placeholder placeholder-sentence w-2/5"></div>
                  <div class="placeholder placeholder-sentence w-3/5"></div>
                  <div class="placeholder placeholder-sentence w-1/5"></div>
                </td>
              </tr>
            </template>
            <template v-for="user in users" v-else>
              <tr :key="user.id">
                <td>
                  <p><router-link class="has-text-weight-bold" :to="{ name: 'users.detail', params: { id: user.id }}" >{{ user.first_name }} {{user.last_name }}</router-link></p>
                  <p class="has-text-grey">{{ user.email }}</p>
                  <p>{{ (user.is_admin) ? 'Administrator' : 'Standard' }}</p>
                </td>
                <td class="has-text-right">
                  <p>{{ user.is_suspended|enabled }}</p>
                  <p class="has-text-grey is-hidden-touch">Last login: {{ humanize(user.last_login_at) }}</p>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <!-- Pagination Controls -->
        <template v-if="meta !== null && links !== null">
          <b-pagination
              :current="currentPage"
              :per-page="perPage"
              :rounded="true"
              :total="meta.total"
              size="is-small"
              @change="paginate">
          </b-pagination>
        </template>
      </div>
    </div>
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showCreateModal"
      :can-cancel="['x']"
    >
      <user-create v-on:processed="processed"></user-create>
    </b-modal>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import Breadcrumbs from '@/components/Breadcrumbs'
import UserCreate from './UserCreate'
export default {
  components: {
    'breadcrumbs': Breadcrumbs,
    'user-create': UserCreate
  },
  computed: {
    query: function () {
      let params = {
        page: this.currentPage
      }
      if (this.name !== null) {
        params['name'] = this.name
      }
      return params
    }
  },
  created: function () {
    this.search = debounce((input) => {
      this.name = input
      this.currentPage = 1
    }, 1000)
  },
  data: function () {
    return {
      currentPage: 1,
      perPage: 15,
      users: [],
      links: null,
      meta: null,
      errors: [],
      showCreateModal: false,
      loading: true,
      name: null,
      searchQuery: null
    }
  },
  filters: {
    enabled: function (isSuspended) {
      return (isSuspended) ? 'Suspended' : 'Active'
    }
  },
  methods: {
    humanize: function (value) {
      if (value !== null && 'date' in value) {
        return this.$moment.utc(value.date).fromNow()
      }
      return 'Never'
    },
    processed: function () {
      this.loadUsers()
    },
    paginate: function (page) {
      this.currentPage = page
      this.loadUsers()
    },
    loadUsers: function () {
      this.$store.dispatch('process')
      this.loading = true
      this.$axios.get('users', {
        params: this.query
      }).then(response => {
        this.users = response.data.data
        this.meta = response.data.meta
        this.links = response.data.links
        this.$store.dispatch('rest')
        this.loading = false
      })
    }
  },
  mounted: function () {
    this.loadUsers()
  },
  props: [],
  watch: {
    name: function () {
      this.loadUsers()
    }
  }
}
</script>
