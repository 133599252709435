<template lang="html">
  <section class="inventory-table">

    <div class="pb-sm has-border-bottom has-border-secondary has-border-lg">
      <div class="columns is-mobile">
        <div class="column is-three-fifths">
          <h6 class="has-text-weight-bold is-size-6">{{ (reference.vendor.name === 'Eastbay') ? 'Eastbay' : reference.vendor.name }} Inventory</h6>
        </div>
        <div class="column is-two-fifths">
          <p class="has-text-right">
            <a class="is-size-7 text-grey"
               href="#"
               v-text="(showColumnDefinitions) ? 'Hide Help' : 'Show Help'"
               @click.prevent="showColumnDefinitions = ! showColumnDefinitions"
            ></a>
          </p>
        </div>
      </div>
    </div>
    <div class="my-sm pa-md has-background-white-ter is-size-7 rounded-lg" v-if="showColumnDefinitions">
      <p class="mb-md">
        <span class="has-text-weight-bold">OH:</span>
        Inventory currently 'on hand.' The date
        underneath the quantity represents when inventory was last updated.
      </p>
      <p class="">
        <span class="has-text-weight-bold">OO:</span>
        Inventory currently 'on order'. Stock is expected to become
        available for order on the date listed underneath the quantity.
      </p>
    </div>

    <table class="table is-hoverable is-fullwidth is-narrow is-size-7">
      <thead>
        <tr>
          <th class="has-text-centered">Size</th>
          <th class="has-text-centered">OH</th>
          <th class="has-text-centered">OO</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in reference.variations" :key="item.id">
          <td class="table-secondary has-text-centered has-text-weight-bold">
            <span :title="'ID: ' + item.id">{{ item.size.name }}</span>
          </td>
          <!-- if there's only 1 item -->
          <template v-if="item.inventory !== null">
            <td class='has-text-centered'>
              <template v-if="reference.vendor.name === 'Eastbay'">
                <p v-text="isCurrent(item.inventory) ? item.inventory.on_hand : 0"></p>
                <p class="is-size-8 has-text-grey-light" v-text="$moment.utc(item.inventory.updated_at.date).local().format('MMM D')"></p>
              </template>
              <template v-else>
                <p v-text="item.inventory.on_hand"></p>
                <p class="is-size-8 has-text-grey-light" v-text="$moment.utc().local().format('MMM D')"></p>
              </template>
            </td>
            <td class='has-text-centered'>
              <p>{{ item.inventory.on_order }}</p>
              <template v-if="item.inventory.available_on !== null">
                <p class="is-size-8 has-text-grey-light" v-text="simpleDate(item.inventory.available_on)"></p>
              </template>
              <template v-else>
                <p class="is-size-8 has-text-grey-light">--</p>
              </template>
            </td>
          </template>
          <template v-else>
            <td class="has-text-centered">
              <p>0</p>
              <p class="is-size-7 has-text-grey-light">--</p>
            </td>
            <td class="has-text-centered">
              <p>0</p>
              <p class="is-size-7 has-text-grey-light">--</p>
            </td>
          </template>
        </tr>
      </tbody>
    </table>

  </section>
</template>

<script>
export default {

  components: {

  },
  computed: {

  },
  data: function () {
    return {
      showColumnDefinitions: false,
      style_id: this.reference.id
    }
  },
  filters: {

  },
  methods: {
    simpleDate: function (value) {
      if (value !== null) {
        return this.$moment(value).format('MMM D')
      }
      return ''
    },
    isCurrent: function (inventory) {
      if (inventory.updated_at === null) return true
      return (this.$moment().diff(this.$moment.utc(inventory.updated_at.date), 'hours') < 36)
    }
  },
  mounted: function () {

  },
  props: ['reference'],
  watch: {

  }
}
</script>
