<template lang="html">
  <form @submit.prevent="editUser" novalidate autocomplete="false" v-if="user !== null">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit User</p>
      </header>
      <section class="modal-card-body">
        <div class="mb-4">
          <div class="columns">
            <div class="column is-half">
              <b-field
                label="First Name"
                :type="('first_name' in errors) ? 'is-danger' : ''"
                :message="('first_name' in errors) ? errors['first_name'][0] : ''"
                expanded>
                <b-input
                  type="text"
                  name="first_name"
                  v-model="first_name"
                ></b-input>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field
                label="Last Name"
                :type="('last_name' in errors) ? 'is-danger' : ''"
                :message="('last_name' in errors) ? errors['last_name'][0] : ''"
                expanded>
                <b-input
                  type="text"
                  name="last_name"
                  v-model="last_name"
                ></b-input>
              </b-field>
            </div>
          </div>
          <div>
            <label class="label mb-6">Roles</label>
            <div class="field">
              <b-checkbox name='is_admin' v-model="is_admin">User is an Admin</b-checkbox>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot justify-end">
        <button type="button" class="button" @click="close()">Close</button>
        <button type="submit" class="button is-primary" :disabled="saving">Update User</button>
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  computed: {

  },
  data: function () {
    return {
      saving: false,
      errors: [],
      first_name: (this.user !== null) ? this.user.first_name : null,
      last_name: (this.user !== null) ? this.user.last_name : null,
      is_admin: (this.user !== null) ? this.user.is_admin : null
    }
  },
  methods: {
    formData: function () {
      return {
        first_name: this.first_name,
        last_name: this.last_name,
        is_admin: this.is_admin
      }
    },
    resetForm: function () {
      this.errors = []
      this.first_name = this.user.first_name
      this.last_name = this.user.last_name
      this.saving = false
    },
    close: function () {
      this.resetForm()
      this.$parent.close()
    },
    editUser: function () {
      this.saving = true
      this.$axios.patch('users/' + this.user.id, this.formData()).then(response => {
        this.saving = false
        this.$emit('updated')
        this.$parent.close()
      }).catch(error => {
        this.saving = false
        if (error.response && error.response.status === 422) {
          this.errors = error.response.data.errors
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      })
    }
  },
  mixins: [],
  props: ['user']
}
</script>
