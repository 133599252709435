<template lang="html">
  <form @submit.prevent="toggleUserStatus" novalidate autocomplete="false" v-if="user !== null">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <template v-if="user.is_suspended">
            Reactivate User
          </template>
          <template v-else>
            Suspend User
          </template>
        </p>
      </header>
      <section class="modal-card-body">
        <p class="py-lg has-text-centered">
          <template v-if="user.is_suspended">
            Are you sure you want to reactivate this user?
          </template>
          <template v-else>
            Are you sure you want to suspend this user?
          </template>
        </p>
      </section>
      <footer class="modal-card-foot justify-end">
        <button type="button" class="button" @click="close()">Cancel</button>
        <button type="submit" class="button is-primary" :disabled="saving">
          <template v-if="user.is_suspended">
            Reactivate User
          </template>
          <template v-else>
            Suspend User
          </template>
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  computed: {

  },
  created: function () {

  },
  data: function () {
    return {
      saving: false
    }
  },
  methods: {
    formData: function () {
      return {
        status: !this.user.is_suspended
      }
    },
    close: function () {
      this.$parent.close()
    },
    toggleUserStatus: function () {
      this.saving = true
      this.$axios.put('users/' + this.user.id + '/status', this.formData()).then(response => {
        this.saving = false
        this.$emit('updated')
        this.$parent.close()
      })
    }
  },
  mixins: [ ],
  props: ['user']
}
</script>
