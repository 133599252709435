import Users from '../views/Users'
import UserDetail from '../views/UserDetail'
export default [
  {
    path: '/admin/users',
    name: 'users',
    component: Users,
    meta: {
      title: 'Users'
    }
  }, {
    path: '/admin/users/:id',
    name: 'users.detail',
    component: UserDetail,
    meta: {
      title: 'User Detail'
    }
  }
]
