import MapBrands from '../views/MapBrands'
import MapSizes from '../views/MapSizes'
export default [
  {
    path: '/admin/map/brands',
    name: 'map.brands',
    component: MapBrands
  }, {
    path: '/admin/map/sizes',
    name: 'map.sizes',
    component: MapSizes
  }
]
