<template>
  <div class="section">
    <div class="container">
      <!-- Breadcrumbs -->
      <breadcrumbs>
        <li class="is-active"><a href="#">Update Password</a></li>
      </breadcrumbs>
      <!-- Content -->
      <div class="has-background-white rounded-lg pa-xl">
        <!-- Two columns -->
        <div class="columns mb-lg">
          <div class="column is-narrow">
            <ul class="px-md">
              <li class="mb-sm"><router-link :to="{ name: 'profile', params: {} }" exact>Profile</router-link></li>
              <li class="mb-sm"><router-link :to="{ name: 'password', params: {} }" exact>Password</router-link></li>
            </ul>
          </div>
          <div class="column">
            <!-- Header -->
            <div class="has-border-bottom pb-md mb-lg">
              <p class="is-size-4 has-text-weight-bold">Update Password</p>
            </div>
            <!-- Form -->
            <form @submit.prevent="updatePassword" novalidate autocomplete="off">
              <div class="columns mb-lg">
                <div class="column is-half">
                  <!-- Old Password -->
                  <b-field
                    label="Old Password"
                    :type="('old_password' in errors) ? 'is-danger' : ''"
                    :message="('old_password' in errors) ? errors['old_password'][0] : ''">
                    <b-input
                      type="password"
                      name="old_password"
                      class="mb-sm"
                      placeholder="Old Password"
                      v-model="old_password"
                    ></b-input>
                  </b-field>
                  <!-- New Password -->
                  <b-field
                    label="New Password"
                    :type="('new_password' in errors) ? 'is-danger' : ''"
                    :message="('new_password' in errors) ? errors['new_password'][0] : ''">
                    <b-input
                      autocomplete="new-password"
                      class="mb-sm"
                      name="new_password"
                      placeholder="New Password"
                      type="password"
                      v-model="new_password"
                    ></b-input>
                  </b-field>
                  <!-- New Password Confirmation -->
                  <b-field
                    label="Confirm New Password"
                    :type="('new_password_confirmation' in errors) ? 'is-danger' : ''"
                    :message="('new_password_confirmation' in errors) ? errors['new_password_confirmation'][0] : ''">
                    <b-input
                      autocomplete="new-password"
                      type="password"
                      name="new_password_confirmation"
                      class="mb-sm"
                      placeholder="New Password"
                      v-model="new_password_confirmation"
                    ></b-input>
                  </b-field>
                </div>
              </div>
              <button type="submit" class="button is-primary is-medium" :disabled="$store.getters.busy">
                Update Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
export default {
  components: {
    'breadcrumbs': Breadcrumbs
  },
  data: function () {
    return {
      old_password: null,
      new_password: null,
      new_password_confirmation: null,
      errors: {}
    }
  },
  methods: {
    formData: function () {
      return {
        'old_password': this.old_password,
        'new_password': this.new_password,
        'new_password_confirmation': this.new_password_confirmation
      }
    },
    updatePassword: function () {
      this.$store.dispatch('process')
      this.$axios.patch('profile/password', this.formData(), {
        headers: { 'Authorization': this.$store.getters.token }
      }).then(response => {
        this.$store.dispatch('rest')
        this.resetForm()
        this.$buefy.toast.open({ type: 'is-success', message: 'Password updated successfully.' })
      }).catch(error => {
        this.$store.dispatch('rest')
        if (error.response && error.response.status === 422) {
          this.errors = error.response.data.errors
          this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
        }
      })
    },
    resetForm: function () {
      this.old_password = null
      this.new_password = null
      this.new_password_confirmation = null
      this.errors = {}
    }
  }
}
</script>
