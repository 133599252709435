<template lang="html">
  <section class="search">
    <div class="container">

      <!-- Breadcrumbs -->
      <breadcrumbs>
        <li class="is-active"><a href="#">Maintenance</a></li>
      </breadcrumbs>

      <!-- Content -->
      <div class="columns">
        <div class="column is-half mx-auto">
          <div class="has-background-white rounded-lg py-6 px-6">
            <p class="has-text-weight-bold has-text-centered">Sorry, but the app is down for maintenance.</p>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
export default {

  components: {
    'breadcrumbs': Breadcrumbs
  }

}
</script>

<style lang="css">
</style>
