<template>
  <div>
    <!-- Header -->
    <div class="pb-xs has-border-bottom has-border-secondary has-border-lg">
      <div class="columns is-mobile">
        <div class="column">
          <p class="has-text-weight-bold">Nike Inventory</p>
        </div>
        <div class="column is-narrow">
          <p class="has-text-right">
            <a class="is-size-7 has-text-grey-light"
               href="#"
               @click.prevent="$emit('show:help')"
            ><b-icon icon="radio-tower" ></b-icon></a>
          </p>
        </div>
      </div>
    </div>
    <!-- Inventory Found -->
    <div v-if="!loading && style !== null">
      <InventoryTable :items="style.variations" />
    </div>
    <!-- Not Found -->
    <div v-else-if="!loading && style === null">
      <div class="my-lg pa-lg rounded-lg is-size-7" style="background:#EEF1F9">
        <div class="has-text-centered has-text-grey-light">
          <p class="has-text-centered mb-xs">
            <b-icon
              icon="information-outline"
              size="is-large"
              custom-class="has-text-grey-light">
            </b-icon>
          </p>
          <p class="">
            Nike API failed to return inventory.
          </p>
          <p>{{ reference.style_number }}</p>
        </div>
      </div>
    </div>
    <!-- Loading -->
    <div v-else class="my-lg px-md py-lg rounded-lg is-size-7" style="background:#EEF1F9">
      <div class="has-text-centered has-text-grey-light">
        <p class="has-text-centered mb-1">
          <b-icon
            icon="loading"
            size="is-large"
            custom-class="spin">
          </b-icon>
        </p>
        <p>Checking with Nike...</p>
        <p>Style #{{ reference.style_number }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import InventoryTable from '../components/InventoryTable'
export default {
  components: {
    InventoryTable
  },
  data () {
    return {
      loading: true,
      style: null
    }
  },
  computed: {
    endpoint () {
      return 'nike/inventory'
    },
    json () {
      return {
        style_number: this.reference.style_number
      }
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$axios.post(this.endpoint, this.json).then(response => {
        this.style = response.data
      }).catch(() => {

      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: ['reference']
}
</script>
