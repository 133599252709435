import Invoices from '../views/Invoices'
import InvoiceDetail from '../views/InvoiceDetail'
import ActiveUsers from '../views/ActiveUsers'
export default [
  {
    path: '/admin/billing/invoices/:id',
    name: 'invoice.detail',
    component: InvoiceDetail
  }, {
    path: '/admin/billing/invoices',
    name: 'invoices',
    component: Invoices
  }, {
    path: '/admin/billing/active-users',
    name: 'active.users',
    component: ActiveUsers
  }
]
