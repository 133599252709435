<template>
  <div class="section">
    <div class="container">
      <!-- Breadcrumbs -->
      <breadcrumbs>
        <li class="is-active"><a href="#">Dashboard</a></li>
      </breadcrumbs>
      <!-- Content -->
      <div class="columns is-centered">
        <div class="column is-half">
          <div class="has-background-white rounded-lg pa-xl">
            <p class="has-text-weight-bold has-text-centered has-text-centered mb-md">Select a task to get started...</p>
            <router-link :to="{ name: 'search', params: {} }" exact class="button is-primary is-fullwidth is-medium">Check Inventory</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
export default {

  components: {
    'breadcrumbs': Breadcrumbs
  }

}
</script>

<style lang="css">
</style>
