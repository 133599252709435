<template lang="html">
  <div class="section">
    <div class="container">

      <!-- Breadcrumbs -->
      <breadcrumbs>
        <li><router-link :to="{ name: 'admin', params: {} }" exact>Admin</router-link></li>
        <li class="is-active"><a href="#">Map Brands</a></li>
      </breadcrumbs>

      <!-- Content -->
      <div class="has-background-white rounded-lg p-6">
        <!-- Toolbar -->
        <div class="columns is-mobile">
          <div class="column">
            <h2 class="text-3xl">Imports</h2>
          </div>
        </div>
        <table class="table is-hoverable is-fullwidth text-sm">
          <thead>
            <tr>
              <th>Monitor</th>
              <th class="has-text-right">Result</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="loading">
              <tr><td colspan="5" class="has-text-centered text-grey"><p class="py-8">Loading data...</p></td></tr>
            </template>
            <template v-else-if="imports.length > 0" v-for="importjob in imports">
              <tr :key="importjob.id">
                <td>
                  <p class="has-text-weight-bold">{{ importjob.vendor.name }} </p>
                  <p class="text-sm text-grey-dark">{{ importjob.filename }}</p>
                </td>
                <td class="text-right align-middle">
                  <template v-if="importjob.status == 'completed'">
                    <p class="has-text-weight-bold text-green">{{ importjob.status|capitalize }}</p>
                  </template>
                  <template v-else-if="importjob.status == 'failed'">
                    <p class="has-text-weight-bold text-red">{{ importjob.status|capitalize }}</p>
                  </template>
                  <template v-else>
                    <p class="has-text-weight-bold">{{ importjob.status|capitalize }}</p>
                  </template>
                  <p class="text-sm text-grey-dark">
                    {{ $moment.utc(importjob.created_at.date).format('lll')}}
                  </p>
                  <p class="text-sm text-grey-dark" v-if="importjob.duration">
                    {{ $moment.duration(importjob.duration,'seconds').humanize() }}
                  </p>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
export default {
  components: {
    'breadcrumbs': Breadcrumbs
  },
  data: function () {
    return {
      loading: false,
      imports: []
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  methods: {
    loadImports: function () {
      this.loading = true
      this.$axios.get('imports').then(response => {
        this.imports = response.data.data
        this.loading = false
      })
    }
  },
  mounted: function () {
    this.loadImports()
  },
  props: [],
  watch: {}
}
</script>
