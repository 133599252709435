<template>
  <div class="section">
    <div class="container">
      <!-- Breadcrumbs -->
      <breadcrumbs>
        <li class="is-active"><a href="#">Profile</a></li>
      </breadcrumbs>
      <!-- Content -->
      <div class="has-background-white rounded-lg pa-xl">
        <!-- Two columns -->
        <div class="columns">
          <div class="column is-narrow">
            <ul class="px-md">
              <li class="mb-sm"><router-link :to="{ name: 'profile' }" exact>Profile</router-link></li>
              <li class="mb-sm"><router-link :to="{ name: 'password' }" exact>Password</router-link></li>
            </ul>
          </div>
          <div class="column">
            <!-- Header -->
            <div class="has-border-bottom pb-md mb-lg">
              <p class="is-size-4 has-text-weight-bold"> {{ $store.getters.fullname }}</p>
              <p class="has-text-grey is-size-7">
                ID: {{ $store.getters.user_id }}
                &bull; {{ $store.getters.email }}
              </p>
            </div>
            <!-- Form -->
            <form @submit.prevent="updateProfile" novalidate autocomplete="off">
              <div class="columns mb-lg">
                <div class="column is-half">
                  <!-- First Name -->
                  <b-field
                    label="First Name"
                    :type="('first_name' in errors) ? 'is-danger' : ''"
                    :message="('first_name' in errors) ? errors['first_name'][0] : ''">
                    <b-input
                      type="text"
                      autocomplete="off"
                      name="first_name"
                      class="mb-md"
                      placeholder="First Name"
                      v-model="first_name"
                    ></b-input>
                  </b-field>
                  <!-- Last Name -->
                  <b-field
                    label="Last Name"
                    :type="('last_name' in errors) ? 'is-danger' : ''"
                    :message="('last_name' in errors) ? errors['last_name'][0] : ''">
                    <b-input
                      type="text"
                      autocomplete="off"
                      name="last_name"
                      class="mb-md"
                      placeholder="Last Name"
                      v-model="last_name"
                    ></b-input>
                  </b-field>
                </div>
              </div>
              <button type="submit" class="button is-primary is-medium" :disabled="$store.getters.busy">
                Update Profile
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
export default {
  components: {
    'breadcrumbs': Breadcrumbs
  },
  created () {

  },
  data: function () {
    return {
      first_name: this.$store.getters.first_name,
      last_name: this.$store.getters.last_name,
      errors: {}
    }
  },
  filters: {

  },
  methods: {
    formData: function () {
      return {
        'first_name': this.first_name,
        'last_name': this.last_name
      }
    },
    updateProfile: function () {
      this.$store.dispatch('process')
      this.$axios.patch('profile', this.formData(), {
        headers: { 'Authorization': this.$store.getters.token }
      }).then(response => {
        this.errors = {}
        this.$store.dispatch('identify')
        this.$buefy.toast.open({
          type: 'is-success', message: 'Profile updated successfully.'
        })
      }).catch(error => {
        this.$store.dispatch('rest')
        if (error.response && error.response.status === 422) {
          this.errors = error.response.data.errors
          this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
        }
      })
    },
    processed: function () {

    }
  },
  props: [],
  watch: {

  }
}
</script>
