<template>
  <div class="section">
    <div class="container">
      <!-- Breadcrumbs -->
      <breadcrumbs>
        <li><router-link :to="{ name: 'admin', params: {} }" exact>Admin</router-link></li>
        <li class="is-active"><a href="#">Active Users</a></li>
      </breadcrumbs>
      <!-- Content -->
      <div class="has-background-white rounded-lg pa-xl">
        <!-- Toolbar -->
        <div class="columns">
          <div class="column">
            <h2 class="is-size-3">Active Users</h2>
          </div>
          <div class="column">
            <div class="select is-fullwidth">
              <select v-model="period">
                <option v-for="month in months" :key="month.value" :value="month.value">{{ month.label }}</option>
              </select>
            </div>
          </div>
        </div>
        <!-- Content -->
        <div class="mb-lg">
          <div class="columns">
            <div class="column">
              <template v-if="loading">
                <p class="has-text-centered py-4">Loading Active Users...</p>
              </template>
              <template v-else>
                <table class="table is-fullwidth is-size-7">
                  <tr>
                    <th class="text-lg" style="color:green">Active Users ({{ active.length }})</th>
                    <th class="has-text-right">Logged At</th>
                  </tr>
                  <tr v-for="user in active" :key="user.id">
                    <td>
                      <p><router-link class="has-text-weight-bold" :to="{ name: 'users.detail', params: { id: user.id} }">{{ user.first_name }} {{ user.last_name }}</router-link></p>
                      <p>{{ user.email }}</p>
                    </td>
                    <td class="has-text-right">
                      {{ $moment(user.activity[0].logged).format('MMM D') }}
                    </td>
                  </tr>
                </table>
              </template>
            </div>
            <div class="column">
              <template v-if="loading">
                <p class="has-text-centered py-4">Loading Inactive Users...</p>
              </template>
              <template v-else>
                <table class="table is-fullwidth is-size-7">
                  <tr>
                    <th class="has-text-danger" style="color:red">Inactive Users ({{ inactive.length }})</th>
                  </tr>
                  <tr v-for="user in inactive" :key="user.id">
                    <td>
                      <p><router-link class="has-text-weight-bold" :to="{ name: 'users.detail', params: { id: user.id} }">{{ user.first_name }} {{ user.last_name }}</router-link></p>
                      <p>{{ user.email }}</p>
                    </td>
                  </tr>
                </table>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
export default {
  components: {
    'breadcrumbs': Breadcrumbs
  },
  computed: {
    months: function () {
      let start = this.$moment('2018-10-01')
      let end = this.$moment()
      let months = []
      /* eslint-disable */
      while (end > start || start.format('M') === end.format('M')) {
        months.push({
          label: start.format('MMMM YYYY'),
          value: start.format('YYYY-MM')
        })
        start.add(1, 'month')
      }
      /* eslint-disable */
      return months
    },
    month: function () {
      let arr = this.period.split('-')
      return parseInt(arr[1])
    },
    year: function () {
      let arr = this.period.split('-')
      return parseInt(arr[0])
    },
    active: function () {
      let active = []
      this.users.forEach(function (user) {
        if (user.activity.length > 0) {
          active.push(user)
        }
      })
      return active
    },
    inactive: function () {
      let inactive = []
      this.users.forEach(function (user) {
        if (user.activity.length === 0) {
          inactive.push(user)
        }
      })
      return inactive
    }
  },
  data: function () {
    return {
      period: this.$moment().format('YYYY-MM'),
      loading: false,
      users: []
    }
  },
  filters: {

  },
  methods: {
    loadUsers: function () {
      this.loading = true
      this.$axios.get('billing/active-users/' + this.year + '/' + this.month).then(response => {
        this.users = response.data.data
        this.loading = false
      })
    }
  },
  mounted: function () {
    this.loadUsers()
  },
  props: [],
  watch: {
    period: function (value) {
      this.loadUsers()
    }
  }
}
</script>
