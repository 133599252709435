<template lang="html">
  <div class="section">
    <div class="container">

      <!-- Breadcrumbs -->
      <breadcrumbs>
        <li><router-link :to="{ name: 'admin', params: {} }" exact>Admin</router-link></li>
        <li class="is-active"><a href="#">Map Brands</a></li>
      </breadcrumbs>

      <!-- Content -->
      <div class="has-background-white rounded-lg px-4 py-4">
        <!-- Toolbar -->
        <div class="columns is-mobile">
          <div class="column">
            <h2 class="text-3xl mb-2">Map Brands</h2>
            <p>
              The following brands were recently discovered in a data feed,
              but didn't match any existing brands. For SKU matching purposes,
              it is important to make sure we don't have duplicate or mislabeled
              brand names.
            </p>
          </div>
        </div>
        <!-- Table -->
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Unmapped Name</th>
              <th class="text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="loading">
              <tr><td colspan="5" class="has-text-centered text-grey"><p class="py-8">Loading data...</p></td></tr>
            </template>
            <template v-else-if="mappings.length == 0">
              <tr><td colspan="5" class="has-text-centered text-grey"><p class="py-8">No unmapped brands. Good job!</p></td></tr>
            </template>
            <template v-else>
              <tr v-for="brand in mappings" :key="brand.id">
                <td><a href='#' @click.prevent='mapping=brand'>{{ brand.match }}</a></td>
                <td></td>
              </tr>
            </template>
          </tbody>
        </table>

      </div>
    </div>

    <!-- Edit User Modal -->
    <b-modal
      has-modal-card
      v-if="mapping !== null"
      v-on:close="clearFocus"
      :active.sync="showMapModal"
      :can-cancel="['x']"
      ><map-brand-modal v-on:updated="processed" :mapping="mapping"></map-brand-modal>
    </b-modal>

  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
import MapBrandModal from './MapBrandModal'
export default {
  components: {
    'breadcrumbs': Breadcrumbs,
    'map-brand-modal': MapBrandModal
  },
  data: function () {
    return {
      loading: false,
      mappings: [],
      showMapModal: false,
      mapping: null
    }
  },
  filters: {

  },
  methods: {
    loadMappings: function () {
      this.loading = true
      this.$axios.get('mappings', {
        params: {
          mapped: 0,
          type: 'brand'
        }
      }).then(response => {
        this.mappings = response.data.data
        this.loading = false
      })
    },
    processed: function () {

    },
    clearFocus: function () {
      this.mapping = null
    }
  },
  mounted: function () {
    this.loadMappings()
  },
  props: [],
  watch: {
    mapping: function (value) {
      if (value !== null) {
        this.showMapModal = true
      }
    }
  }
}
</script>
