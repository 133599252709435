<template>
  <div class="forgot-password">
    <form novalidate>
      <b-notification class="has-text-centered" type="is-danger" :closable="false" >
        Forgot Password
      </b-notification>
    </form>
    <p class="has-text-grey has-text-centered">
      <router-link to="/auth/login">Sign In</router-link>
    </p>
  </div>
</template>

<script>

export default {
  beforeCreate: function () {
    document.body.className = 'auth'
  },
  computed: {

  },
  data: function () {
    return {
      username: null,
      password: null
    }
  },
  methods: {
    validationError: function (field) {
      if (!field.$dirty) return '  '

      if ('required' in field && !field.required) return 'This field is required.'
      if ('email' in field && !field.email) return 'Please enter a valid email address.'
    },
    attempt: function () {
      if (this.isValid()) {
        this.$axios.get('/')
      }

      if (!this.validate()) {
        this.showalert = true
      }
    }
  }
}
</script>
