import Search from '../views/Search'
import SearchDetail from '../views/SearchDetail'
export default [
  {
    path: '/inventory',
    name: 'search',
    component: Search
  }, {
    path: '/inventory/:id',
    name: 'search.detail',
    component: SearchDetail
  }
]
