<template>
  <section class="search-vendors">
    <!-- Results -->
    <template v-if="loading">
      <div class="my-md pa-md is-size-7 rounded-lg" style="background:#EEF1F9">
        <div class="has-text-centered has-text-grey-light">
          <p class="mb-sm">
            <b-icon
              icon="loading"
              size="is-large"
              custom-class="spin">
            </b-icon>
          </p>
          <p class="has-text-centered">
            Checking with external vendors for style number
          </p>
          <p>{{ reference.style_number }}</p>
        </div>
      </div>
    </template>
    <template v-else-if="styles.length === 0">
      <div class="my-md pa-md is-size-7 rounded-lg" style="background:#EEF1F9">
        <div class="has-text-centered has-text-grey-light">
          <p class="mb-sm">
            <b-icon
              icon="information-outline"
              size="is-large"
              custom-class="has-text-grey-light">
            </b-icon>
          </p>
          <p class="">
            No additional vendors found for style
          </p>
          <p>{{ reference.style_number }}</p>
        </div>
      </div>
    </template>
    <template v-else>
      <inventory :reference="styles[0]"></inventory>
    </template>
  </section>
</template>

<script>
import StyleInventory from './StyleInventory'
export default {
  components: {
    'inventory': StyleInventory
  },
  computed: {

  },
  data: function () {
    return {
      loading: true,
      styles: [],
      showColumnDefinitions: false
    }
  },
  filters: {

  },
  methods: {
    lookForStyles: function () {
      this.loading = true
      this.$axios.get('styles/' + this.$route.params.id + '/vendors').then(response => {
        this.styles = response.data.data
        this.loading = false
      })
    }
  },
  mounted: function () {
    setTimeout(() => {
      this.lookForStyles()
    }, 500)
  },
  props: ['reference'],
  watch: {}
}
</script>
