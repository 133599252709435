<template lang="html">
  <nav class="breadcrumb">
    <ul>
      <li><router-link to="/"><span class="icon"><i class="mdi mdi-home"></i></span></router-link></li>
      <slot></slot>
    </ul>
  </nav>
</template>

<script>
export default {
}
</script>
