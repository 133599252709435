<template>
  <div class="section">
    <div class="container">
      <!-- Breadcrumbs -->
      <breadcrumbs>
        <li><router-link :to="{ name: 'admin', params: {} }" exact>Admin</router-link></li>
        <li class="is-active"><a href="#" @click.prevent>Monitoring</a></li>
      </breadcrumbs>

      <!-- Content -->
      <div class="has-background-white rounded-lg pa-lg">
        <!-- Toolbar -->
        <div class="columns is-mobile">
          <div class="column">
            <h2 class="is-size-3">Feed Monitoring</h2>
          </div>
        </div>
        <table class="table is-hoverable is-fullwidth is-size-7">
          <thead>
            <tr>
              <th>Monitor</th>
              <th class="has-text-right">Result</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="loading">
              <tr><td colspan="5" class="has-text-centered has-text-grey"><p class="py-8">Loading data...</p></td></tr>
            </template>
            <template v-else-if="checks.length > 0" v-for="check in checks">
              <tr :key="check.id">
                <td>
                  <p class="has-text-weight-bold">{{ check.monitor.vendor.name }} {{ check.monitor.disk.driver.toUpperCase() }}</p>
                  <p class="text-sm text-grey-dark">{{ check.message }}</p>
                </td>
                <td class="has-text-right">
                  <template v-if="check.result == 'success'">
                    <p class="has-text-weight-bold has-text-success">{{ check.result|capitalize }}</p>
                  </template>
                  <template v-else-if="check.result == 'skipped'">
                    <p class="has-text-weight-bold">{{ check.result|capitalize }}</p>
                  </template>
                  <template v-else-if="check.result == 'failed'">
                    <p class="has-text-weight-bold has-text-danger">{{ check.result|capitalize }}</p>
                  </template>
                  <template v-else>
                    <p class="has-text-weight-bold">{{ check.result|capitalize }}</p>
                  </template>
                  <p class="text-sm text-grey-dark">{{ $moment.utc(check.created_at.date).local().format('lll')}}</p>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
export default {
  components: {
    'breadcrumbs': Breadcrumbs
  },
  data: function () {
    return {
      loading: false,
      checks: []
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  methods: {
    loadChecks: function () {
      this.loading = true
      this.$axios.get('monitoring').then(response => {
        this.checks = response.data.data
        this.loading = false
      })
    }
  },
  mounted: function () {
    this.loadChecks()
  }
}
</script>
