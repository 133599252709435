import Vue from 'vue'
import Router from 'vue-router'
import AdminRoutes from './modules/Admin/router'
import AuthRoutes from './modules/Auth/router'
import BillingRoutes from './modules/Billing/router'
import DashboardRoutes from './modules/Dashboard/router'
import DeveloperRoutes from './modules/Developer/router'
import ImportRoutes from './modules/Imports/router'
import MappingsRoutes from './modules/Mappings/router'
import MonitoringRoutes from './modules/Monitoring/router'
import ProfileRoutes from './modules/Profile/router'
import SearchRoutes from './modules/Search/router'
import StatsRoutes from './modules/Stats/router'
import UserRoutes from './modules/Users/router'
import store from './store'

Vue.use(Router)

const baseRoutes = []
const routes = baseRoutes.concat(
  AdminRoutes,
  AuthRoutes,
  BillingRoutes,
  DashboardRoutes,
  DeveloperRoutes,
  ImportRoutes,
  MappingsRoutes,
  MonitoringRoutes,
  ProfileRoutes,
  SearchRoutes,
  StatsRoutes,
  UserRoutes
)

const router = new Router({
  routes: routes,
  linkActiveClass: 'is-active',
  mode: 'history'
})

// register global guard
router.beforeEach((to, from, next) => {
  // if maintenance mode is on and the path is anything but the maintenance page, redirect
  if (store.getters.maintenanceMode && to.path !== '/maintenance') {
    next({ path: '/maintenance' })
    return
  }
  // if maintenance mode is off and the path is to the maintenance page, redirect to home
  if (!store.getters.maintenanceMode && to.path === '/maintenance') {
    next({ path: '/' })
    return
  }
  // set the page title
  document.title = ('meta' in to && typeof to.meta.title !== 'undefined')
    ? 'Eastbay Team Sales: ' + to.meta.title
    : 'Eastbay Team Sales'
  // check for presence of accesss token
  if (store.getters.authenticated || to.path === '/auth/login' || localStorage.getItem('token') !== null) {
    store.dispatch('navigate')
    next()
    return
  }
  // redirect all others to login page
  next({ path: '/auth/login' })
})

export default router
