<template lang="html">
  <div class="section">
    <div class="container">

      <!-- Breadcrumbs -->
      <breadcrumbs>
        <li><router-link :to="{ name: 'admin', params: {} }" exact>Admin</router-link></li>
        <li class="is-active"><a href="#">Invoices</a></li>
      </breadcrumbs>

      <!-- Content -->
      <div class="has-background-white rounded-lg p-6">
        <!-- Toolbar -->
        <div class="columns is-mobile">
          <div class="column">
            <h2 class="text-3xl">Invoices</h2>
          </div>
        </div>
        <table class="table is-hoverable is-fullwidth text-sm">
          <thead>
            <tr>
              <th>Invoice</th>
              <th class="has-text-right">Amount</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="loading">
              <tr><td colspan="5" class="has-text-centered text-grey"><p class="py-8">Loading data...</p></td></tr>
            </template>
            <template v-else-if="invoices.length > 0" v-for="invoice in invoices">
              <tr :key="invoice.id">
                <td>
                  <p><router-link class="has-text-weight-bold" :to="{ name: 'invoice.detail', params: { id: invoice.id }}" >{{ invoice.period }}</router-link></p>
                  <p class="text-sm text-grey-dark">{{ invoice.invoice_number }}</p>
                </td>
                <td class="text-right align-middle">
                  <p class="text-sm text-grey-dark">${{ $numbro(invoice.total_due).format({thousandSeparated: true}) }}</p>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
export default {
  components: {
    'breadcrumbs': Breadcrumbs
  },
  computed: {

  },
  data: function () {
    return {
      loading: false,
      invoices: []
    }
  },
  filters: {

  },
  methods: {
    loadInvoices: function () {
      this.loading = true
      this.$axios.get('billing/invoices').then(response => {
        this.invoices = response.data.data
        this.loading = false
      })
    }
  },
  mounted: function () {
    this.loadInvoices()
  },
  props: [],
  watch: {}
}
</script>
