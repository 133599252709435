<template>
  <div class="section">
    <div class="mt-md pa-md is-size-7 rounded-lg" v-if="showColumnDefinitions" style="background:#EEF1F9">
      <p class="mb-md">
        <span class="has-text-weight-bold">OH:</span>
        Inventory currently 'on hand.' The date
        underneath the quantity represents when inventory was last updated.
      </p>
      <p class="">
        <span class="has-text-weight-bold">OO:</span>
        Inventory currently 'on order'. Stock is expected to become
        available for order on the date listed underneath the quantity.
      </p>
    </div>
    <!-- Results -->
    <template v-if="loading">
      <div class="my-md px-md py-lg rounded-lg is-size-7" style="background:#EEF1F9">
        <p class="has-text-centered mb-sm">
          <b-icon
            icon="loading"
            size="is-large"
            custom-class="spin has-text-grey">
          </b-icon>
        </p>
        <p class="has-text-centered">
          Checking Eastbay inventory for style number "{{ reference.style_number }}"
        </p>
      </div>
    </template>
    <template v-else-if="styles.length === 0">
      <div class="my-md px-md py-lg rounded-lg is-size-7" style="background:#EEF1F9">
        <p class="has-text-centered mb-sm">
          <b-icon
            icon="information-outline"
            size="is-large"
            custom-class="has-text-grey-light">
          </b-icon>
        </p>
        <p class="has-text-centered">
          No Eastbay styles matching style number "{{ reference.style_number }}"
          were found.
        </p>
      </div>
    </template>
    <template v-else>
      <inventory :reference="styles[0]"></inventory>
    </template>
  </div>
</template>

<script>
import StyleInventory from './StyleInventory'
export default {
  components: {
    'inventory': StyleInventory
  },
  data: function () {
    return {
      loading: true,
      styles: [],
      showColumnDefinitions: false
    }
  },
  methods: {
    lookForStyles: function () {
      this.loading = true
      this.$axios.get('styles/' + this.$route.params.id + '/eastbay').then(response => {
        this.styles = response.data.data
        this.loading = false
      })
    }
  },
  mounted: function () {
    setTimeout(() => {
      this.lookForStyles()
    }, 500)
  },
  props: ['reference']
}
</script>
