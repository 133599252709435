<template>
  <div class="section">
    <div class="container">
      <!-- Breadcrumbs -->
      <breadcrumbs>
        <li class="is-active"><a href="#" @click.prevent>Developer Settings</a></li>
      </breadcrumbs>
      <!-- Content -->
      <div class="has-background-white rounded-lg pa-xl">
        <!-- Tokens -->
        <div class="mb-xl">
          <!-- Toolbar -->
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-size-3">API Access Tokens</h2>
            </div>
            <div class="column">
              <p class="has-text-right">
                <button class="button is-primary is-medium rounded" @click="showCreateModal = true">
                  Create Token
                </button>
              </p>
            </div>
          </div>
          <!-- Tokens -->
          <table class="table is-fullwidth is-size-7">
            <thead>
              <tr>
                <th>Token</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-if="loading">
                <tr><td colspan="5" class="has-text-centered has-text-grey"><p class="py-lg">Loading tokens...</p></td></tr>
              </template>
              <template v-else-if="tokens.length == 0">
                <tr><td colspan="5" class="has-text-centered has-text-grey"><p class="py-lg">No API Tokens Found!</p></td></tr>
              </template>
              <template v-for="token in tokens" v-else>
                <tr :key="token.id">
                  <td>
                    <p class=""><a class="has-text-info has-text-weight-bold" @click.prevent="inspect_token = token">{{ token.name }}</a></p>
                    <p class="has-text-grey">
                      Expires {{ $moment.utc(token.expires_at).format('MMM DD, YYYY') }}
                    </p>
                  </td>
                  <td class="has-text-right">
                    <button class="button is-primary is-small" @click="revoke_token_id = token.id">Revoke</button>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>

        <!-- Authentication -->
        <div class="mb-xl is-size">
          <h2 class="has-text-grey has-border-bottom is-uppercase mb-md">Authentication</h2>
          <div>
            <p class="mb-md">
              The RevCascade Extension API uses access tokens to authenticate all clients over HTTPS.
              You may generate an API access token above. To make an authenticated
              request, please submit your token in the Authorization header as
              shown below:
            </p>
            <div class="rounded-lg mb-md">
              <pre>GET /v1/styles/lookup?style_number=XXXXXX
Authorization: Bearer 0oW0r4m1pjIlb5UJujZA5iVus334gddFg...</pre>
            </div>
          </div>
        </div>

        <!-- Style Lookup -->
        <div class="mb-xxl">
          <h2 class="has-text-grey has-border-bottom is-uppercase mb-md">Style Lookup</h2>
          <div>
            <h6 class="has-text-weight-bold mb-sm">Lookup a <u>single</u> Style (with all sizes)</h6>
            <p class="mb-lg">
              Styles represent collections of items that usually share the same
              color (but the items are offered in different sizes).
              Use the following route to lookup exactly 1 style using either an
              Eastbay style number or the manufacturer's style number.
            </p>
            <div class="columns is-gapless is-mobile mb-0">
              <div class="column is-one-fifth">
                <div class="has-background-info has-text-white pa-md has-text-weight-bold has-text-centered">GET</div>
              </div>
              <div class="column">
                <div class="has-background-white-ter pa-md is-family-monospace py-md">
                  v1/styles/lookup
                </div>
              </div>
            </div>
            <p class="mb-md has-text-danger">
              Please note: each request MUST include one of the following
              parameters in the query string.
            </p>
            <table class="table is-size-7 is-fullwidth">
              <tr>
                <th style="width:30%">Param</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>
                  <p class="has-text-weight-bold">eastbay_style_number</p>
                  <p class='text-blue'>string</p>
                </td>
                <td>An eastbay style number.</td>
              </tr>
              <tr>
                <td>
                  <p class="has-text-weight-bold">style_number</p>
                  <p class='text-blue'>string</p>
                </td>
                <td>A manufacturer's style number.</td>
              </tr>
            </table>
          </div>
        </div>

        <!-- Variation Lookup -->
        <div class="mb-8">
          <h2 class="has-text-grey has-border-bottom is-uppercase mb-md">Variation/Size Lookup</h2>
          <div class="mb-3 text-sm">
            <h6 class="has-text-weight-bold mb-sm">Lookup a <u>single</u> size (using a style identifier & size string)</h6>
            <p class="mb-lg">
              Variations are distinct physical items that are generally assigned
              an item-level sku/upc & size. Variations inherit color from their parent style.
            </p>
            <div class="columns is-gapless is-mobile">
              <div class="column is-one-fifth">
                <div class="has-background-info has-text-white pa-md has-text-weight-bold has-text-centered">GET</div>
              </div>
              <div class="column">
                <div class="has-background-white-ter pa-md is-family-monospace py-md">
                  v1/variations/lookup
                </div>
              </div>
            </div>
            <p class="mb-lg has-text-danger">
              Please note: variation/size lookups should include a style number
              and a size together OR just a single SKU.
            </p>
            <table class="table is-size-7 is-fullwidth">
              <tr>
                <th style="width:30%">Param</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>
                  <p class="has-text-weight-bold">eastbay_style_number</p>
                  <p class='text-blue'>string</p>
                </td>
                <td>An eastbay style number.</td>
              </tr>
              <tr>
                <td>
                  <p class="has-text-weight-bold">eastbay_native_size</p>
                  <p class='text-blue'>string</p>
                </td>
                <td>
                  <p class="mb-3">Eastbay's native string to describe the size (e.g. "3XL" or "10.0").</p>
                  <p class="bg-grey-lighter p-3 rounded-lg mb-3">
                    To improve compatibility and matching with manufacturer styles,
                    Eastbay's sizes are mapped to "sizes" such that "3XL" and "XXXL"
                    will have the same size_id (and same size object in the
                    'size' attribute). Using this filter will bypass
                    this mapping and search against Eastbay's native size string.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p class="has-text-weight-bold">eastbay_sku</p>
                  <p class='text-blue'>string</p>
                </td>
                <td>
                  Eastbay's unique identifier for the variation. If a eastbay_sku
                  is specified, no other filters are necessary (as the eastbay_sku
                  should map to exactly 1 variation)</td>
              </tr>
            </table>
          </div>
        </div>

      </div>
    </div>

    <!-- Create Modal -->
    <b-modal :active.sync="showCreateModal" @close="resetForm">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Create New Token</p>
        </header>
        <section class="modal-card-body">
          <b-field
            label="Token Name"
            :type="('name' in errors) ? 'is-danger' : ''"
            :message="('name' in errors) ? errors['name'][0] : ''"
            expanded>
            <b-input
              autocomplete="off"
              type="text"
              name="name"
              v-model="name"
            ></b-input>
          </b-field>
          <div class="control mb-2" v-if="token !== null">
            <label class="label">Access Token</label>
            <textarea class="textarea text-xs mb-2" readonly style="min-height:400px" v-text="token"></textarea>
            <p class="text-red">
              Please copy and paste the ENTIRE access token now. Once this
              window is closed, you will not be able to view the token again.
            </p>
          </div>
        </section>
        <footer class="modal-card-foot justify-end">
          <button type="submit" class="button is-primary" @click="createToken" :disabled="this.saving">Create Token</button>
        </footer>
      </div>
    </b-modal>

    <!-- Inspect Modal -->
    <b-modal :active.sync="showInspectModal" @close="inspect_token = null" :token="inspect_token">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Inspect Access Token</p>
        </header>
        <section class="modal-card-body" v-if="inspect_token !== null">
          <div class="mb-lg">
            <label class="label">Name</label>
            <p class="">{{ inspect_token.name }}</p>
          </div>
          <div class="mb-lg">
            <label class="label">Token ID</label>
            <div class="control mb-md">
              <textarea class="textarea" readonly v-text="inspect_token.id"></textarea>
            </div>
            <p class="is-size-7 has-text-grey">Please note: the token id above
              is NOT a full access token. Access tokens are only displayed
              once (when the token is created).
            </p>
          </div>
          <div class="mb-lg">
            <label class="label">Status</label>
            <p class="">{{ (inspect_token.revoked) ? 'Revoked' : 'Active' }}</p>
          </div>
          <div class="mb-lg">
            <label class="label">Expires At</label>
            <p class="">{{ $moment.utc(inspect_token.expires_at).format('MMM DD, YYYY @ h:MM A') }}</p>
          </div>
        </section>
        <footer class="modal-card-foot justify-end">
          <button type="button" class="button" @click="inspect_token = null" >Close</button>
        </footer>
      </div>
    </b-modal>

    <!-- Revoke Modal -->
    <b-modal :active.sync="showRevokeModal" @close="revoke_token_id = null">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Revoke Access Token</p>
        </header>
        <section class="modal-card-body">
          Are you sure you want to revoke this access token? Any applications
          that might still use this token will stop working immediatly. This action
          is not reversible.
        </section>
        <footer class="modal-card-foot justify-end">
          <button type="button" class="button" @click="revoke_token_id = null">Close</button>
          <button type="submit" class="button is-danger" @click="revokeToken" :disabled="revoking">Revoke Token</button>
        </footer>
      </div>
    </b-modal>

  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
export default {
  components: {
    'breadcrumbs': Breadcrumbs
  },
  created () {

  },
  data: function () {
    return {
      // list tokens
      loading: false,
      tokens: [],
      // insepct token
      showInspectModal: false,
      inspect_token: null,
      // revoke token
      revoke_token_id: null,
      showRevokeModal: false,
      revoking: false,
      // create token
      showCreateModal: false,
      saving: false,
      name: null,
      token: null,
      errors: []
    }
  },
  filters: {

  },
  methods: {
    loadTokens: function () {
      this.loading = true
      this.$axios.get('profile/access-tokens').then(response => {
        this.tokens = response.data
        this.loading = false
      })
    },
    createToken: function () {
      this.saving = true
      this.$axios.post('profile/access-tokens', { name: this.name }).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Token created.' })
        this.errors = []
        this.token = response.data.accessToken
        this.loadTokens()
      }).catch(error => {
        this.saving = false
        if (error.response && error.response.status === 422) {
          this.errors = error.response.data.errors
          this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
        }
      })
    },
    resetForm: function () {
      this.name = null
      this.token = null
      this.errors = []
      this.saving = false
    },
    revokeToken: function () {
      this.revoking = true
      this.$axios.delete('profile/access-tokens/' + this.revoke_token_id).then(response => {
        this.revoking = false
        this.revoke_token_id = null
        this.$buefy.toast.open({
          type: 'is-success', message: 'Token revoked.'
        })
        this.loadTokens()
      })
    }
  },
  mounted: function () {
    this.loadTokens()
  },
  props: [],
  watch: {
    inspect_token: function (value) {
      if (value !== null) {
        this.showInspectModal = true
        return
      }
      this.showInspectModal = false
    },
    revoke_token_id: function (value) {
      if (value !== null) {
        this.showRevokeModal = true
        return
      }
      this.showRevokeModal = false
    }
  }
}
</script>
