<template>
  <section class="invoice-detail">

    <div class="container">
      <!-- Breadcrumbs -->
      <breadcrumbs>
        <li><router-link :to="{ name: 'invoices' }" exact>Invoices</router-link></li>
        <template v-if="invoice === null && loading">
        <li class="is-active text-grey"><a href="#">Loading...</a></li>
        </template>
        <template v-else-if="invoice !== null">
          <li class="is-active"><a href="#">#{{ (invoice !== null) ? invoice.id : '' }}</a></li>
        </template>
      </breadcrumbs>
      <!-- Detail -->
      <div class="has-background-white rounded-lg p-6">
        <template v-if="loading || invoice === null">
          <p class="py-8 has-text-centered text-grey">Loading...</p>
        </template>
        <template v-else>
          <!-- Header -->
          <div class="mb-4 pb-3 border-b">
            <p class="is-size-4 has-text-weight-bold">{{ invoice.period }}</p>
          </div>
          <!-- Invoice Details -->
          <div class="">
            <div class="columns">
              <div class="column is-one-quarter">
                <p class="has-text-weight-bold">Invoice Number </p>
              </div>
              <div class="column is-three-quarters">
                {{ invoice.invoice_number }}
              </div>
            </div>
            <div class="columns">
              <div class="column is-one-quarter">
                <p class="has-text-weight-bold">Total Due</p>
              </div>
              <div class="column is-three-quarters">
                {{ $numbro(invoice.total_due).formatCurrency({thousandSeparated: true, mantissa: 2}) }}
              </div>
            </div>
            <div class="columns">
              <div class="column is-one-quarter">
                <p class="has-text-weight-bold">Invoice Lines</p>
              </div>
              <div class="column is-three-quarters">
                <p class="mb-4" v-for="line in invoice.lines" :key="line.id">
                  {{ line.description }}<br>
                  <span class="text-sm text-grey-darker">{{ $numbro(line.amount).formatCurrency({ thousandSeparated: true, mantissa: 2 }) }}</span>
                </p>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

  </section>
</template>

<script>

import Breadcrumbs from '@/components/Breadcrumbs'
export default {
  components: {
    'breadcrumbs': Breadcrumbs
  },
  created () {
    this.loadInvoice()
  },
  data: function () {
    return {
      loading: false,
      invoice: null
    }
  },
  filters: {

  },
  methods: {
    loadInvoice: function () {
      this.loading = true
      this.$axios.get('billing/invoices/' + this.$route.params.id).then(response => {
        this.invoice = response.data.data
        this.loading = false
      })
    }
  },
  mixins: [],
  props: [],
  watch: {
    '$route': function () {
      if (this.$route.params.id !== this.invoice.id) {
        this.loadInvoice()
      }
    }
  }
}
</script>
