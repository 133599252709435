<template lang="html">
  <div>
    <!-- Counts -->
    <div class="mb-4">
      <p class="has-text-centered text-xl mb-2">Styles</p>
      <table class="table is-fullwidth bg-grey-lighter">
        <tbody>
          <tr>
            <th class="">Total</th>
            <td class="text-right">
              <template v-if="loading">
                <span class="icon"><i class="mdi mdi-autorenew spin"></i></span>
              </template>
              <template v-else>
                {{ $numbro(stats.styles).format({thousandSeparated: true}) }}
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Styles -->
    <div class="mb-4">
      <p class="has-text-centered text-xl mb-2">Styles Added (Last 7)</p>
      <template v-if="loading">
        <p class="has-text-centered py-2">Loading...</p>
      </template>
      <template v-else>
        <table class="table is-fullwidth bg-grey-lighter">
          <tbody>
            <tr v-for='day in stats.recent' :key='day.date'>
              <th class="">{{ $moment(day.date).format('MMM D') }}</th>
              <td class="text-right">{{ $numbro(day.num_styles).format({thousandSeparated: true}) }}</td>
            </tr>
          </tbody>
        </table>
      </template>
    </div>
  </div>

</template>

<script>
export default {

  data: function () {
    return {
      loading: true,
      stats: null
    }
  },
  methods: {
    loadStats: function () {
      this.loading = true
      this.$axios.get('stats/styles').then(response => {
        this.stats = response.data.data
        this.loading = false
      })
    }
  },
  mounted: function () {
    this.loadStats()
  }

}
</script>
