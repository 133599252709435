<template>
  <div class="section">
    <div class="container">
      <!-- Breadcrumbs -->
      <breadcrumbs>
        <li><router-link :to="{ name: 'users' }" exact>Users</router-link></li>
        <template v-if="user === null && loading">
        <li class="is-active text-grey"><a href="#">Loading...</a></li>
        </template>
        <template v-else-if="user !== null">
          <li class="is-active"><a href="#">{{ (user !== null) ? user.email : '' }}</a></li>
        </template>
      </breadcrumbs>
      <!-- Detail -->
      <div class="has-background-white rounded-lg pa-xl">
        <template v-if="loading || user === null">
          <p class="py-lg has-text-centered text-grey">Loading...</p>
        </template>
        <template v-else>
          <!-- Header -->
          <div class="mb-md">
            <p class="is-size-4 has-text-weight-bold">{{ user.first_name }} {{ user.last_name }}</p>
            <p class="has-text-grey is-size-7">User ID: {{ user.id }}</p>
          </div>
          <!-- Actions -->
          <div class="has-border-top has-border-bottom mb-lg py-md">
            <div class="columns">
              <div class="column is-one-quarter">
                <button class="button is-dark is-outlined is-medium is-fullwidth" @click="showEditModal = true">
                  Edit Settings
                </button>
              </div>
              <div class="column is-one-quarter">
                <button class="button is-light is-medium is-fullwidth" @click="showPasswordModal = true">
                  Reset Password
                </button>
              </div>
              <div class="column is-one-quarter">
                <button class="button is-light is-medium is-fullwidth" @click="showStatusModal = true">
                  <template v-if="user.is_suspended">
                    Reactivate User
                  </template>
                  <template v-else>
                    Suspend User
                  </template>
                </button>
              </div>
            </div>
          </div>
          <!-- User Details -->
          <div class="">
            <div class="columns">
              <div class="column is-one-quarter">
                <p class="has-text-weight-bold">First Name</p>
              </div>
              <div class="column is-three-quarters">
                {{ user.first_name }}
              </div>
            </div>
            <div class="columns">
              <div class="column is-one-quarter">
                <p class="has-text-weight-bold">Last Name</p>
              </div>
              <div class="column is-three-quarters">
                {{ user.last_name }}
              </div>
            </div>
            <div class="columns">
              <div class="column is-one-quarter">
                <p class="has-text-weight-bold">Email</p>
              </div>
              <div class="column is-three-quarters">
                {{ user.email }}
              </div>
            </div>
            <div class="columns">
              <div class="column is-one-quarter">
                <p class="has-text-weight-bold">Enabled?</p>
              </div>
              <div class="column is-three-quarters">
                {{ (user.is_suspended) ? "Suspended" : "Active" }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- Edit User Modal -->
    <b-modal
      has-modal-card
      v-if="user != null"
      :active.sync="showEditModal"
      :can-cancel="['x']"
      ><user-edit-modal v-on:updated="processed" :user="user"></user-edit-modal>
    </b-modal>

    <!-- Change Password Modal -->
    <b-modal
      has-modal-card
      v-if="user != null"
      :active.sync="showPasswordModal"
      :can-cancel="['x']"
      ><user-password-modal v-on:updated="passwordChanged" :user="user"></user-password-modal>
    </b-modal>

    <!-- Change User Status -->
    <b-modal
      has-modal-card
      v-if="user != null"
      :active.sync="showStatusModal"
      :can-cancel="['x']"
      ><user-status-modal v-on:updated="statusChanged" :user="user"></user-status-modal>
    </b-modal>

  </div>
</template>

<script>

import Breadcrumbs from '@/components/Breadcrumbs'
import UserEditModal from './UserEditModal'
import UserPasswordModal from './UserPasswordModal'
import UserStatusModal from './UserStatusModal'

export default {
  beforeCreate: function () {
    document.body.className = ''
  },
  components: {
    'breadcrumbs': Breadcrumbs,
    'user-edit-modal': UserEditModal,
    'user-password-modal': UserPasswordModal,
    'user-status-modal': UserStatusModal
  },
  created () {
    this.loadUser()
  },
  data: function () {
    return {
      loading: false,
      showEditModal: false,
      showPasswordModal: false,
      showStatusModal: false,
      user: null
    }
  },
  filters: {
    yesno: function (value) {
      return (value) ? 'Yes' : 'No'
    }
  },
  methods: {
    loadUser: function () {
      this.loading = true
      this.$axios.get('users/' + this.$route.params.id).then(response => {
        this.user = response.data.data
        this.loading = false
      })
    },
    processed: function () {
      this.$buefy.toast.open({ type: 'is-success', message: 'User updated.' })
      this.loadUser()
    },
    passwordChanged: function () {
      this.$buefy.toast.open({ type: 'is-success', message: 'User password updated.' })
      this.loadUser()
    },
    statusChanged: function () {
      this.$buefy.toast.open({ type: 'is-success', message: 'User status updated.' })
      this.loadUser()
    }
  },
  mixins: [],
  props: [],
  watch: {
    '$route': function () {
      if (this.$route.params.id !== this.user.id) {
        this.loadUser()
      }
    }
  }
}
</script>
