import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '@/router'
import { version } from '../package.json'

// import qs from 'qs'
Vue.use(Vuex)

// set axios defaults
axios.defaults.baseURL = process.env.VUE_APP_API_URI
axios.defaults.withCredentials = true

export default new Vuex.Store({
  state: {
    initialized: false,
    token: null,
    user: null,
    processing: false,
    show_nav: false,
    show_hamburger: false,
    redirecting: false,
    authenticating: false,
    catastrophe: false,
    version: version,
    presentationMode: false,
    maintenanceMode: (process.env.VUE_APP_MAINTENANCE === 'true'),
    globalMessage: null
  },
  mutations: {
    token: function (state, token) {
      state.token = token
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    },
    initialized: function (state) {
      state.initialized = true
    },
    redirecting: function (state) {
      state.redirecting = true
    },
    navigation: function (state, enabled) {
      state.show_nav = (enabled)
      state.show_hamburger = false
    },
    navigate: function (state) {
      state.show_hamburger = false
    },
    hamburger: function (state) {
      state.show_hamburger = !state.show_hamburger
    },
    loading: function (state) {
      state.loading = true
    },
    processing: function (state) {
      state.processing = true
    },
    rest: function (state) {
      state.processing = false
    },
    present: function (state, payload) {
      state.presentationMode = (payload === true)
    },
    authenticate: function (state, payload) {
      state.user = payload
    },
    logout: function (state) {
      state.token = null
      state.user = null
      state.show_nav = false
      state.show_hamburger = false
    }
  },
  getters: {
    version: () => {
      return version
    },
    initialized: state => {
      return state.initialized
    },
    authenticated: state => {
      return (state.user !== null)
    },
    redirecting: state => {
      return state.redirecting
    },
    catastrophe: state => {
      return state.catastrophe
    },
    busy: state => {
      return state.processing
    },
    user_id: state => {
      return (state.user !== null && state.user.id) ? state.user.id : null
    },
    email: state => {
      return (state.user !== null && state.user.email) ? state.user.email : null
    },
    first_name: state => {
      return (state.user !== null && state.user.first_name) ? state.user.first_name : null
    },
    last_name: state => {
      return (state.user !== null && state.user.last_name) ? state.user.last_name : null
    },
    fullname: state => {
      return (state.user !== null) ? state.user.first_name + ' ' + state.user.last_name : null
    },
    timezone: state => {
      return 'America/Chicago'
    },
    token: state => {
      return (state.token) ? 'Bearer ' + state.token : null
    },
    show_nav: state => {
      return state.show_nav
    },
    shortname: state => {
      return (state.user !== null) ? state.user.first_name + ' ' + state.user.last_name.substring(0, 1) + '.' : null
    },
    is_revcascade: state => {
      return (state.user !== null && state.user.is_revcascade)
    },
    is_admin: state => {
      return (state.user !== null && (state.user.is_revcascade || state.user.is_admin))
    },
    is_developer: state => {
      return (state.user !== null && (state.user.is_revcascade || state.user.is_admin))
    },
    presentationMode: state => {
      return state.presentationMode
    },
    maintenanceMode: state => {
      return (state.maintenanceMode)
    },
    hasGlobalMessage: state => {
      return (state.globalMessage !== null)
    },
    globalMessage: state => {
      return state.globalMessage
    }
  },
  actions: {
    /**
     * Setup additional configurations when the application is first launched,
     * and call action to identify the user and check on the status of the
     * user session.
     */
    initialize ({ commit, dispatch, state }) {
      // if the app is in maintence mode, redirect to the maintenence page
      // route that inf
      if (this.getters.maintenanceMode) {
        router.push({ path: '/maintenance' })
        commit('initialized')
        return
      }
      // register global interceptors with axios. we're doing this now
      // (and not earlier) since we have access to vuex and can update the
      // app with the appropriate state depending on the response
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          dispatch('redirectGuest')
        } else if (error.response.status === 412) {
          dispatch('redirectReset')
        } else if (error.response.status === 500) {
          commit('catastrophe')
        }
        return Promise.reject(error)
      })
      // dispatch action to determine if user is authenticated (we introduce
      // an artificial wait to prevent flashing content)
      setTimeout(() => { dispatch('identify') }, 250)
    },
    /**
     * Check if the user has a valid session by calling the profile endpoint
     * If the request fails, we will assume the user is not authenticated,
     * so we'll redirect them to the login page.
     */
    identify ({ commit, dispatch }) {
      // inform loading indicators
      commit('processing')
      // check for a token in local storage. if a token is found, configure
      // axios to use the token by default on every future request. if no
      // token is found, redirect the user to the login page.
      let token = localStorage.getItem('token')
      if (token !== null) {
        commit('token', token)
      } else {
        dispatch('redirectGuest')
        return
      }
      // using our access token, make a request to the 'profile' endpoint
      // that will return all of the authenticated user's data (assuming
      // the token is valid)
      axios.get('profile').then(response => {
        commit('authenticate', response.data.data)
        commit('navigation', true)
        commit('initialized')
      })
      // check the status of presentation mode
      let presentationMode = localStorage.getItem('present')
      if (presentationMode === 'true') {
        commit('present', true)
      }
      // inform loading indicators
      commit('rest')
    },
    /**
     * If a user is not authenticated, configure the state such that the
     * user can be warned of an impending redirect. After some time,
     * redirect the user to the login page.
     */
    redirectGuest ({ commit }) {
      commit('logout')
      commit('redirecting')
      setTimeout(function () {
        router.push({ path: '/auth/login' })
        commit('initialized')
        commit('rest')
      }, 500)
    },
    /**
     * If the server indicates that the user must reset the password, redirect
     * the user appropriatly. the server ensures that any funture actions will
     * be restricted, but we don't want to enable any navigation elements
     */
    redirectReset ({ commit }) {
      commit('redirecting')
      setTimeout(function () {
        router.push({ path: '/profile/set-password', query: { force: true } })
        commit('initialized')
        commit('rest')
      }, 500)
    },
    /**
     * Set a token in local storage, then commit the token to state.
     *
     */
    authenticate ({ commit, dispatch }, payload) {
      // set the token in local storage
      localStorage.setItem('token', payload.token)
      commit('token', payload.token)
      commit('authenticate', payload.user)
      if (payload.user.must_reset) {
        dispatch('redirectReset')
        return
      }
      // otherwise, go home
      commit('navigation', true)
      commit('initialized')
      router.push({ name: 'dashboard' })
    },
    /**
     * As the user navigates within the application, we might need to
     * clean up various pieces of state (e.g. hide open modals and menus).
     * Could also be used to fire analytics??
     */
    navigate ({ commit }) {
      commit('navigate')
    },
    /**
     * Configure the state required to show the navigation menu when the
     * user taps or clicks on the hamburger menu icon.
     */
    hamburger ({ commit }) {
      commit('hamburger')
    },
    /**
     * Configure the state required to show the user the status of a
     * pending asynchronous request (e.g. loading icons or spinners)
     */
    process ({ commit }) {
      commit('processing')
    },
    /**
     * Configure the state required to show the user that the application
     * is no longer processing an asynchronous request.
     */
    rest ({ commit }) {
      commit('rest')
    },
    /**
     * Log a user out out the application by posting a request to the api
     * followed by destroying user information from the local state.
     */
    logout ({ commit, dispatch, state }) {
      axios.post('/auth/logout').then(response => {
        localStorage.removeItem('token')
        commit('logout')
        dispatch('redirectGuest')
      })
    },
    /**
     * Toggle the app into "presenter" mode so that we can hide certain data
     * from the UI (e.g product costs). Persist the setting in local storage
     * to make it sticky.
     */
    present ({ commit }, payload) {
      if (payload) {
        localStorage.setItem('present', true)
        commit('present', true)
        return
      }
      localStorage.setItem('present', false)
      commit('present', false)
    }
  }
})
