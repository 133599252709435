<template lang="html">
  <div class="section">
    <div class="container">

      <!-- Breadcrumbs -->
      <breadcrumbs>
        <li class="is-active"><a href="#">Admin</a></li>
      </breadcrumbs>

      <!-- Content -->
      <div class="columns is-centered">
        <div class="column is-half">
          <div class="has-background-white rounded-lg pa-xl">
            <div class="mb-xl">
              <p class="has-text-weight-bold has-text-centered mb-sm">Administration</p>
              <router-link :to="{ name: 'users', params: {} }" exact class="button is-primary is-outlined is-fullwidth is-medium">Manage Users</router-link>
            </div>
            <!--
            <div class="mb-xl">
              <p class="has-text-weight-bold has-text-centered mb-2">Mappings</p>
              <router-link :to="{ name: 'map.brands', params: {} }" exact class="button is-primary is-outlined is-fullwidth is-medium mb-2">Map Brands</router-link>
              <router-link :to="{ name: 'map.sizes', params: {} }" exact class="button is-primary is-outlined is-fullwidth is-medium">Map Sizes</router-link>
            </div>
            -->
            <div class="mb-xl">
              <p class="has-text-weight-bold has-text-centered mb-sm">Monitoring & Analytics</p>
              <router-link :to="{ name: 'monitoring', params: {} }" exact class="button is-primary is-outlined is-fullwidth is-medium mb-sm">Feed Monitor</router-link>
              <router-link :to="{ name: 'imports', params: {} }" exact class="button is-primary is-outlined is-fullwidth is-medium mb-sm">Imports</router-link>
              <!-- <router-link :to="{ name: 'stats', params: {} }" exact class="button is-primary is-outlined is-fullwidth is-medium">Statistics</router-link> -->
            </div>
            <div class="mb-xl">
              <p class="has-text-weight-bold has-text-centered mb-sm">Billing</p>
              <!-- <router-link :to="{ name: 'invoices', params: {} }" exact class="button is-primary is-outlined is-fullwidth is-medium mb-sm">Invoices</router-link> -->
              <router-link :to="{ name: 'active.users', params: {} }" exact class="button is-primary is-outlined is-fullwidth is-medium">Active Users</router-link>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
export default {

  components: {
    'breadcrumbs': Breadcrumbs
  }

}
</script>
