<template lang="html">
  <form @submit.prevent="mapBrand" novalidate autocomplete="false" v-if="mapping !== null">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Map Brand</p>
      </header>
      <section class="modal-card-body">
        <div class="mb-4">

          <!-- Show Match Value -->
          <div class="mb-4">
            <p class="has-text-weight-bold mb-1">Unknown Brand Name</p>
            <p>{{ mapping.match }}</p>
          </div>
          <!-- Show search results with radio buttons if found -->
          <div class="mb-4">
            <p class="has-text-weight-bold border border-t-0 border-r-0 border-l-0 pb-3">Possible Matches</p>
            <div v-if="loading">
              <p class="text-grey py-3">Loading...</p>
            </div>
            <div v-else-if="suggestions.length > 0">
              <table class="table is-fullwidth text-sm">
                <tr v-for="suggestion in suggestions" :key="suggestion.id">
                  <td style="width:60px">
                    <b-radio
                      v-model="action"
                      :native-value="'map::' + suggestion.id"
                    ></b-radio>
                  </td>
                  <td>{{ suggestion.name }}</td>
                </tr>
              </table>
            </div>
            <div v-else>
              <p class="text-grey py-3">No Suggestions</p>
            </div>
          </div>
          <!-- -->
          <div class="mb-4">
            <p class="has-text-weight-bold border border-t-0 border-r-0 border-l-0 pb-3">Create New Brand</p>
            <table class="table is-fullwidth text-sm">
              <tr>
                <td style="width:60px">
                  <b-radio
                    v-model="action"
                    native-value="create"
                  ></b-radio>
                </td>
                <td>{{ mapping.match }}</td>
              </tr>
            </table>
          </div>

        </div>
      </section>
      <footer class="modal-card-foot justify-end">
        <button type="button" class="button" @click="close()">Close</button>
        <button type="submit" class="button is-primary" :disabled="saving">Map Brand</button>
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  computed: {

  },
  data: function () {
    return {
      loading: false,
      suggestions: [],
      saving: false,
      action: null
    }
  },
  methods: {
    loadSuggestions: function () {
      this.loading = true
      this.$axios.get('brands/suggestions', {
        params: {
          query: this.mapping.match
        }
      }).then(response => {
        this.suggestions = response.data.data
        this.loading = false
      })
    },
    formData: function () {

    },
    resetForm: function () {

    },
    close: function () {
      this.$parent.close()
    }
  },
  mixins: [],
  mounted: function () {
    this.loadSuggestions()
  },
  props: ['mapping']
}
</script>
